import { addDays, addHours, isAfter } from 'date-fns'
import { observer } from 'mobx-react'
import React from 'react'
import {
  MdAddCircle,
  MdCheck,
  MdError,
  MdLoop,
  MdRemoveCircle
} from 'react-icons/md'

import Alert from '../../components/Alert/Alert'
import Button from '../../components/Button/Button'
import BaseInput from '../../components/Form/BaseInput'
import Label from '../../components/Label/Label'
import Row from '../../components/Row/Row'
import BaseSelect from '../../components/Form/BaseSelect'
import View from '../../components/View/View'
import { CargoDeparture } from '../../api/cargoSelfServiceAPI'
import errorStore, { ErrorLevel } from '../../stores/ErrorStore'
import modalStore from '../../stores/ModalStore'
import reservationStore from '../../stores/ReservationStore'
import { getDate, getTime, isTranslated } from '../../utils/helpers'
import css from './PreliminaryBookingContainer.module.css'
import { withTranslation, WithTranslation } from 'react-i18next'

type PreliminaryBooking = {
  departure: CargoDeparture
  amount: number
  pending?: number
  status?: 'SUCCESS' | 'FAILED' | 'PREVIOUS' | 'LOADING'
}

type State = {
  departureList: CargoDeparture[]
  preliminaryBookings: PreliminaryBooking[]
  requestSent: boolean
}

@observer
class PreliminaryBookingContainer extends React.Component<WithTranslation, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      departureList: [],
      preliminaryBookings: [],
      requestSent: false
    }
  }

  componentDidMount() {
    reservationStore.fetchDeparturePorts().then(() => this.fetchDepartures())
  }

  fetchHiddenDepartures = async (): Promise<string[]> => {
    try {
      const hiddenDeparturesResponse = await fetch('/admin/public/departures')
      const { departures } = await hiddenDeparturesResponse.json()
      return departures
    } catch (e) {
      return []
    }
  }

  fetchDepartures = async () => {
    const earliestAllowed = addHours(Date.now(), 2)

    const hiddenDepartures = await this.fetchHiddenDepartures()

    const departureList = (
      await reservationStore.fetchDeparturesFromCountry(
        new Date(),
        addDays(new Date(), 14)
      )
    ).filter(
      ({ departurePortExtCode, arrivalPortExtCode, departureDatetime }) =>
        !hiddenDepartures.includes(
          `${departurePortExtCode}-${arrivalPortExtCode}-${departureDatetime}`
        ) &&
        departureDatetime &&
        isAfter(new Date(departureDatetime), earliestAllowed)
    )

    const preliminaryBookings = departureList.reduce(
      (acc: PreliminaryBooking[], cur) => {
        if (cur.bookings) {
          const pending = cur.bookings.filter((x) => x.statusCode === 'PS_RQ').length
          if (pending > 0) {
            acc.push({ departure: cur, amount: 0, pending, status: 'PREVIOUS' })
          }
        }
        return acc
      },
      []
    )

    this.setState({ departureList, preliminaryBookings })
  }

  sendBookingRequest = () => {
    this.setState({ requestSent: true })
    const { preliminaryBookings } = this.state

    const promises = preliminaryBookings.map(async (booking, index) => {
      this.setState((state) => {
        const loading = [...state.preliminaryBookings]
        loading[index] = { ...booking, status: 'LOADING' }
        return { preliminaryBookings: loading }
      })

      let status: PreliminaryBooking['status'] = 'SUCCESS'

      try {
        const {
          amount,
          departure: { departureCode, journeyCode }
        } = booking

        for (let i = 0; i < amount; i++) {
          const { data: response } = await reservationStore.makePreliminaryBooking(
            departureCode,
            journeyCode
          )

          if (!response.self) {
            status = 'FAILED'
            break
          }
        }
      } catch (error) {
        status = 'FAILED'
      }

      this.setState((state) => {
        const newState = [...state.preliminaryBookings]
        newState[index] = { ...booking, status }
        return { preliminaryBookings: newState }
      })

      return status === 'FAILED' ? Promise.reject('') : Promise.resolve('')
    }, [])

    Promise.all(promises).then(
      () => {
        modalStore.confirm(
          'Request sent!',
          'Eckerö Line will get back to you shortly with approval or denial.',
          () => {
            this.fetchDepartures()
            this.setState({ requestSent: false })
          }
        )
      },
      () => {
        modalStore.confirm(
          'Something went wrong',
          'One or more request failed, please inspect.',
          () => {
            this.fetchDepartures()
            this.setState({ requestSent: false })
          }
        )
      }
    )
  }

  addRow = (event: any) => {
    const { preliminaryBookings, departureList } = this.state
    const { value } = event.target

    if (!value) {
      errorStore.add('Select a departure', ErrorLevel.warning)
      return
    }
    const newValue = { departure: departureList[value], amount: 1 }
    const exists = preliminaryBookings.find(
      (x) => x.departure.departureCode === newValue.departure.departureCode
    )

    if (exists) {
      errorStore.add('Already selected', ErrorLevel.warning)
      return
    }

    this.setState((state) => {
      const newState = [...state.preliminaryBookings, newValue].sort((a, b) =>
        a.departure.departureDatetime! < b.departure.departureDatetime! ? -1 : 1
      )
      return { preliminaryBookings: newState }
    })
  }

  dropRow = (index: number) => {
    const arr = [...this.state.preliminaryBookings]
    arr.splice(index, 1)
    this.setState({ preliminaryBookings: arr })
  }

  setPort = (event: React.ChangeEvent<HTMLSelectElement>) => {
    reservationStore.setDepartureCountry(event.target.value)
    this.setState({ departureList: [] })
    this.fetchDepartures()
  }

  setAmount = (index: number, value: any) => {
    if (value < 0 || value > 99) {
      return
    }

    const { preliminaryBookings } = this.state
    if (value === 0 && !preliminaryBookings[index].pending) {
      return this.dropRow(index)
    }
    const arr = [...preliminaryBookings]
    arr[index].amount = parseInt(value, 10) || 0
    this.setState({ preliminaryBookings: arr })
  }

  render() {
    const { departureList, preliminaryBookings, requestSent } = this.state
    const { t } = this.props
    const { departureCountries, departureCountryCode } = reservationStore

    return (
      <View title={t('preliminary_booking')}>
        <div className={css.container}>
          <div className={css.card}>
            <div className={css.title}>{t('departures')}</div>
            <div className={css.tableWrapper}>
              <table className={css.departureTable}>
                <thead>
                  <tr className={css.tableHeader}>
                    <th className={css.datetime}>{t('date')}</th>
                    <th className={css.datetime}>{t('time')}</th>
                    <th>{t('route')}</th>
                    <th className={css.amount}>{t('pending')}</th>
                    <th className={css.amount}>{t('add')}</th>
                    <th className={css.status}>{t('status')}</th>
                  </tr>
                </thead>
                <tbody>
                  {preliminaryBookings.length <= 0 ? (
                    <tr className={css.tableRow}>
                      <td colSpan={6}>
                        <div>{t('no_departures_selected')}</div>
                      </td>
                    </tr>
                  ) : (
                    preliminaryBookings.map((departure, index) => {
                      const {
                        amount,
                        pending,
                        departure: {
                          arrivalPortExtCode,
                          departurePortExtCode,
                          departureDatetime
                        },
                        status
                      } = departure
                      const departurePort =
                        reservationStore.findPortByCode(departurePortExtCode || '')
                          .portName || ''
                      const arrivalPort =
                        reservationStore.findPortByCode(arrivalPortExtCode || '')
                          .portName || ''

                      return (
                        <tr className={css.tableRow} key={index}>
                          <td>{getDate(departureDatetime)}</td>
                          <td>{getTime(departureDatetime)}</td>
                          <td>
                            {departurePort} - {arrivalPort}
                          </td>
                          <td className={css.amount}>{pending}</td>
                          <td className={css.amount}>
                            {requestSent ? (
                              amount
                            ) : (
                              <div className={css.changeAmount}>
                                <MdRemoveCircle
                                  onClick={() => this.setAmount(index, amount - 1)}
                                />
                                <div className={css.amountInput}>
                                  <BaseInput
                                    textAlign="center"
                                    px={2}
                                    value={amount}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => this.setAmount(index, event.target.value)}
                                  />
                                </div>
                                <MdAddCircle
                                  onClick={() => this.setAmount(index, amount + 1)}
                                />
                              </div>
                            )}
                          </td>
                          <td className={css.status}>
                            {status === 'LOADING' && (
                              <MdLoop className={css.loading} />
                            )}
                            {((status === 'PREVIOUS' && amount === 0) ||
                              status === 'SUCCESS') && (
                              <MdCheck style={{ color: 'green' }} />
                            )}
                            {status === 'FAILED' && (
                              <MdError style={{ color: 'red' }} />
                            )}
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
            <div className={css.subtitle}>{t('add_departure')}</div>

            <Row bottom left className={css.addRow}>
              <Label vertical wide>
                {t('departure_country')}
                <BaseSelect
                  isDisabled={requestSent}
                  value={departureCountryCode}
                  onChange={this.setPort}
                >
                  {departureCountries.map((countryCode) => (
                    <option key={countryCode} value={countryCode}>
                      {isTranslated(countryCode) ? (
                        <>{t(countryCode)}</>
                      ) : (
                        countryCode
                      )}
                    </option>
                  ))}
                </BaseSelect>
              </Label>
              <Label vertical wide>
                {t('departure')} ({t('allotment')})
                <BaseSelect isDisabled={requestSent} onChange={this.addRow}>
                  <option key="" value="">
                    {t('select_from_dropdown')}
                  </option>
                  {departureList.map((x, index) => (
                    <option key={x.departureCode} value={index}>
                      {getDate(x.departureDatetime)} &nbsp;
                      {getTime(x.departureDatetime!)} &nbsp; (
                      {x.customerAllotments
                        ? x.customerAllotments!.allotments!.highSpaceAllotment!
                            .availableLength
                        : 0}
                      )
                    </option>
                  ))}
                </BaseSelect>
              </Label>
            </Row>
            <div className={css.line} />
            <Row className={css.row}>
              <Alert level={ErrorLevel.warning}>
                {t('any_requests_will_be_handled')}
                <br />
                Mon - Fri &nbsp; 07:00 - 23:00
                <br />
                Sat - Sun &nbsp;08:00 - 15:00
              </Alert>
              <Button disabled={requestSent} onClick={this.sendBookingRequest}>
                {t('send')}
              </Button>
            </Row>
          </div>
        </div>
      </View>
    )
  }
}

export default withTranslation()(PreliminaryBookingContainer)
