import {
  HStack,
  Icon,
  IconProps,
  Text,
  Tooltip,
  TooltipProps
} from '@chakra-ui/react'
import React, { FC } from 'react'

type Props = {
  tooltipProps?: Omit<TooltipProps, 'label' | 'hasArrow' | 'children'>
  tooltipText: React.ReactNode
  icon: React.FC
  label: string
  iconProps?: IconProps
}

const BaseFormLabel: FC<Props> = (props) => {
  const { tooltipText, icon, iconProps, label, tooltipProps } = props

  return (
    <HStack>
      <Text>{label}</Text>
      <Tooltip hasArrow label={tooltipText} {...tooltipProps}>
        <Icon cursor="pointer" as={icon} fill="yellow.400" {...iconProps} />
      </Tooltip>
    </HStack>
  )
}

export default BaseFormLabel
