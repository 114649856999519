import React from 'react'
import { Grid, ThemingProps } from '@chakra-ui/react'
import FormInput from '../../../../components/FormControlled/FormInput'
import Autofill from './Autofill'
import FormSelect from '../../../../components/FormControlled/FormSelect'
import {
  parseToDate,
  removeCyrillicSymbols,
  validatePhoneNumber
} from '../../../../utils/helpers'
import { emailPattern, onlyLettersPatternExp } from '../../../../utils/constants'
import { UseFieldArrayUpdate, useFormContext } from 'react-hook-form'
import { CargoNameListRow } from '../../../../api/cargoSelfServiceAPI'
import reservationStore from '../../../../stores/ReservationStore'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import { ReservationFormValues } from '../../ReservationContainer'
import { CargoForm } from '../../../driverUpdate/DriverUpdateContainer'
import FormRadio from '../../../../components/FormControlled/FormRadio'
import { observer } from 'mobx-react'
import { ErrorIcon } from '../../../../icons'
import BaseFormLabel from '../../../../components/Form/BaseFormLabel'
import { useTranslation } from 'react-i18next'

type Props = {
  id: number
  driver: CargoNameListRow
  formVariant?: ThemingProps['variant']
  updateDriver:
    | UseFieldArrayUpdate<ReservationFormValues, 'drivers'>
    | UseFieldArrayUpdate<CargoForm, 'drivers'>
  formGenderRadio?: boolean
  isFormDisabled?: boolean
}

const currentYear = new Date().getFullYear()

const DriversList = observer((props: Props) => {
  const { id, driver, updateDriver, formVariant, formGenderRadio, isFormDisabled } =
    props
  const { nationalities, genders } = reservationStore

  const { control } = useFormContext<ReservationFormValues>()
  const { t } = useTranslation()

  const [savedDrivers, setSavedDrivers] = useLocalStorage<CargoNameListRow[]>(
    'drivers',
    []
  )

  const handleDelete = (index: number) => {
    setSavedDrivers((drivers) =>
      drivers.filter((_, driverIndex) => driverIndex !== index)
    )
  }

  return (
    <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={3}>
      <FormInput
        rules={{
          validate: (value) =>
            !onlyLettersPatternExp.test(value as string)
              ? 'only_latin_letters'
              : true
        }}
        name={`drivers.${id}.firstName`}
        control={control}
      >
        {({ field, fieldState, errorMessage, formState }) => {
          return (
            <Autofill
              disabled={isFormDisabled}
              drivers={savedDrivers}
              field={field}
              driver={driver}
              formState={formState}
              fieldState={fieldState}
              errorMessage={errorMessage}
              handleSelect={(driver) => {
                updateDriver(id, driver)
              }}
              handleDelete={handleDelete}
              variant={formVariant}
            />
          )
        }}
      </FormInput>
      <FormInput
        disabled={isFormDisabled}
        variant={formVariant}
        label={
          <BaseFormLabel
            tooltipText={t('only_latin_letters')}
            icon={ErrorIcon}
            label={t('lastname')}
            iconProps={{ fill: 'yellow.400' }}
          />
        }
        rules={{
          validate: (value) =>
            !onlyLettersPatternExp.test(value as string)
              ? 'only_latin_letters'
              : true
        }}
        size="md"
        name={`drivers.${id}.lastName`}
        control={control}
      />
      {formGenderRadio ? (
        <FormRadio
          isDisabled={isFormDisabled}
          label={t('gender')}
          name={`drivers.${id}.genderCode`}
          options={genders.map((gender) => ({
            value: gender.genderCode,
            label: <>{t(gender.genderName)}</>
          }))}
          control={control}
        />
      ) : (
        <FormSelect
          isDisabled={isFormDisabled}
          variant={formVariant}
          size="md"
          label={t('gender')}
          control={control}
          name={`drivers.${id}.genderCode`}
        >
          <option value="">{t('select_from_dropdown')}</option>
          {genders.map((gender) => (
            <option key={gender.genderCode} value={gender.genderCode}>
              <>{t(gender.genderName)}</>
            </option>
          ))}
        </FormSelect>
      )}
      <FormSelect
        isDisabled={isFormDisabled}
        variant={formVariant}
        size="md"
        control={control}
        label={t('nationality')}
        name={`drivers.${id}.nationalityExtCode`}
      >
        <option value="">{t('select_from_dropdown')}</option>
        {nationalities.map((nationality) => (
          <option
            key={nationality.countryExtCode}
            value={nationality.countryExtCode}
          >
            {nationality.countryName}
          </option>
        ))}
      </FormSelect>
      <FormInput
        disabled={isFormDisabled}
        variant={formVariant}
        size="md"
        label={t('date_of_birth')}
        setValueAs={parseToDate}
        placeholder="DD.MM.YYYY"
        rules={{
          minLength: { message: 'error.date_format', value: 14 },
          validate: (value = '') => {
            const year = String(value).split(' . ')[2]
            const yearAsNumber = year ? +year : undefined
            if (yearAsNumber === undefined) {
              return true
            }
            if (yearAsNumber <= 1930) {
              return 'error.person_is_too_old'
            }
            if (yearAsNumber > currentYear) {
              return 'error.person_is_not_yet_born'
            }
            return true
          }
        }}
        name={`drivers.${id}.dateOfBirth`}
        control={control}
      />
      <FormInput
        disabled={isFormDisabled}
        variant={formVariant}
        size="md"
        setValueAs={(value) => value?.trim()}
        label={
          <BaseFormLabel
            tooltipText={t('only_numbers_and_plus')}
            icon={ErrorIcon}
            label={t('phone_number')}
            iconProps={{ fill: 'yellow.400' }}
          />
        }
        rules={{
          validate: validatePhoneNumber
        }}
        placeholder="+358123456789"
        name={`drivers.${id}.mobile`}
        control={control}
      />
      <FormInput
        disabled={isFormDisabled}
        variant={formVariant}
        size="md"
        setValueAs={removeCyrillicSymbols}
        label={t('email')}
        rules={{
          validate: (email) => {
            if ((email as string)?.trim()) {
              return emailPattern.test(email as string)
            }
            return true
          }
        }}
        placeholder="email@address"
        name={`drivers.${id}.email`}
        control={control}
      />
    </Grid>
  )
})

export default DriversList
