import { Api as CargoSelfServiceAPI } from '../api/cargoSelfServiceAPI'
import { Api as DriverUpdateAPI } from '../api/driverUpdateAPI'
import { Api as DepartureInfoAPI } from '../api/portDepartureInfoAPI'
import { Api as ExternalPaymentsAPI } from '../api/externalPaymentsAPI'
import { handleApiError, langCode } from '../utils/helpers'
import AuthStore from '../stores/AuthStore'
import initI18n, { LanguageTypes } from '../locales'

const X_CARUS_APISYS = process.env.REACT_APP_X_CARUS_APISYS || ''
const CARES_ENV = process.env.REACT_APP_API_CARRES_ENV || ''

const getHeaders = () => ({
  'accept-language': langCode(initI18n.language as LanguageTypes),
  'x-carus-apisys': X_CARUS_APISYS,
  'x-carus-auth': AuthStore.authorizationToken,
  'content-type': 'application/json'
})

class Api {
  get cargoSelfService() {
    const api = new CargoSelfServiceAPI({
      baseURL: `/api/cargo-self-service/${CARES_ENV}/v1`,
      headers: getHeaders()
    })

    api.instance.interceptors.response.use(undefined, handleApiError)

    return api.cargoSelfService
  }

  get driverUpdate() {
    const api = new DriverUpdateAPI({
      baseURL: `/driver-api/cargo-self-service-driver-update/${CARES_ENV}/v1`,
      headers: getHeaders()
    })

    api.instance.interceptors.response.use(undefined, handleApiError)

    return api.cargoSelfService
  }

  get departureInfo() {
    const api = new DepartureInfoAPI({
      baseURL: `/departure-api/departure-info/${CARES_ENV}/v1`,
      headers: getHeaders()
    })

    api.instance.interceptors.response.use(undefined, handleApiError)

    return api.departureInfo
  }

  get extPayments() {
    const api = new ExternalPaymentsAPI({
      baseURL: `/api/ext-ecommerce-payments/${CARES_ENV}/v1`,
      headers: getHeaders()
    })

    api.instance.interceptors.response.use(undefined, handleApiError)

    return api.extPayments
  }
}

export default new Api()
