import React, { FC, Fragment } from 'react'
import { Box, Divider, Heading, Stack } from '@chakra-ui/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { CargoNameListRow } from '../../api/cargoSelfServiceAPI'
import { CargoForm } from './DriverUpdateContainer'
import DriversList from '../reservation/components/DriverAndServices/DriversList'

type Props = {
  isFormDisabled?: boolean
}

const DriversListUpdate: FC<Props> = ({ isFormDisabled }) => {
  const { control, watch } = useFormContext<CargoForm>()

  const watchFieldArray: CargoNameListRow[] = watch('drivers') || []

  const { fields, update } = useFieldArray<CargoForm, 'drivers'>({
    control,
    name: 'drivers'
  })

  const controlledFields = fields.map(({ id }, index) => ({
    id,
    ...watchFieldArray[index]
  }))

  return (
    <Stack spacing={5}>
      {controlledFields.map((field, i) => (
        <Fragment key={field.id}>
          <Box>
            {controlledFields.length !== 1 && (
              <Heading as="h4" size="sm" mb={2}>
                Driver {i + 1}
              </Heading>
            )}
            <DriversList
              isFormDisabled={isFormDisabled}
              formGenderRadio
              formVariant="custom"
              updateDriver={update}
              id={i}
              driver={field}
            />
          </Box>
          {controlledFields.length - 1 !== i && <Divider />}
        </Fragment>
      ))}
    </Stack>
  )
}

export default DriversListUpdate
