/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Link {
  /** Full url. */
  href?: string
}

export interface ResourceLinks {
  self?: Link
}

export interface Error400 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error400RequiredAction
}

export interface Error401 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error401RequiredAction
}

export interface Error403 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error403RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface ResourceIdentifier {
  identifierType: ResourceIdentifierIdentifierType
  /**
   * @minLength 1
   * @maxLength 1000
   */
  value: string
}

export interface ResourceReference {
  resourceType: ResourceReferenceResourceType
  /**
   * @maxItems 2147483647
   * @minItems 1
   */
  identifiers: ResourceIdentifier[]
}

export interface Error404 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error404RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface Error406 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error406RequiredAction
}

export interface Error409 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error409RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface Error429 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error429RequiredAction
}

/** Cargo unit object */
export interface CreateCargoVehicleUnitRequest {
  /**
   * The registration number of the vehicle
   * @minLength 0
   * @maxLength 20
   */
  registrationNumber?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * the weight of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  tareWeight?: number
  /**
   * the length of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * the width of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 999.99
   * @exclusiveMax false
   */
  width?: number
  /**
   * the height of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  height?: number
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
  /** flag describing if the unit is active or not */
  active?: boolean
}

export interface Error422 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error422RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
  errorSubType?: Error422ErrorSubType
}

export interface CargoBookingRowItem {
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  bookingRowItemReference: string
}

/** This contains information about the driver. Only one of name and name list row number is populated.  */
export interface DriverInfo {
  /**
   * @minLength 0
   * @maxLength 50
   */
  name?: string
  /**
   * @format int32
   * @min 0
   * @max 99999
   */
  nameListRowNumber?: number
}

/** External id used by customers, agents, suppliers etc. */
export interface ExternalId {
  /**
   * Type of external id.
   * @minLength 0
   * @maxLength 10
   */
  externalIdTypeCode: string
  /**
   * Value of external id.
   * @minLength 0
   * @maxLength 128
   */
  value: string
}

/** Journey code object for update requests */
export interface JourneyCodeFieldUpdateMandatory {
  /**
   * journey code in carres
   * @minLength 0
   * @maxLength 10
   */
  value: string
}

export interface UpdateCargoBookingContactDetailRequest {
  /**
   * contact's row number controlled by the backend
   * @format int32
   * @min 0
   * @max 99999
   */
  contactRowNumber?: number
  /**
   * contact type
   * @minLength 0
   * @maxLength 5
   */
  contactType?: string
  /**
   * contact info
   * @minLength 0
   * @maxLength 50
   */
  contactInfo?: string
  /** send info flag */
  sendInfo?: boolean
  /** auto sms flag */
  autoSms?: boolean
}

/** Booking object */
export interface UpdateCargoBookingRequest {
  /**
   * optional field of the booking version number where the changes are based on
   * @min 1
   * @exclusiveMin false
   * @max 99999999999999
   * @exclusiveMax false
   */
  fromBookingVersionCode?: number
  /** flag specifying if terms and conditions have been accepted */
  isTermsAndConditionsAccepted?: boolean
  namelist?: UpdateCargoNameListRowRequest[]
  bookingContacts?: UpdateCargoBookingContactDetailRequest[]
  cargoJourney?: UpdateCargoJourneyRowRequest
  extraBookingLines?: UpdateCargoExtraBookingRowRequest[]
  /**
   * title of the customer
   * @minLength 0
   * @maxLength 5
   */
  titleCode?: string
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * middle name
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName?: string
  /**
   * customer's street address
   * @minLength 0
   * @maxLength 200
   */
  streetAddress?: string
  /**
   * customer's postal code
   * @minLength 0
   * @maxLength 10
   */
  postalCode?: string
  /**
   * customer's postal district
   * @minLength 0
   * @maxLength 50
   */
  postalDistrict?: string
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  stateCode?: string
  /**
   * customer's phone area
   * @minLength 0
   * @maxLength 10
   */
  phoneArea?: string
  /**
   * customer's phone number
   * @minLength 0
   * @maxLength 50
   */
  phoneNumber?: string
  /**
   * Language code used externally
   * @minLength 0
   * @maxLength 10
   */
  languageExtCode?: string
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /**
   * promotion code of the booking
   * @minLength 0
   * @maxLength 10
   */
  promotionCode?: string
  /**
   * Currency code used externally
   * @minLength 0
   * @maxLength 50
   */
  currencyExtCode?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * booking reference
   * @minLength 0
   * @maxLength 60
   */
  bookingReference?: string
}

export interface UpdateCargoBookingRowCabinPropsRequest {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
}

export interface UpdateCargoBookingRowMealPropsRequest {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date */
  startDate?: string
  /**
   * @format int32
   * @min 0
   * @max 23
   */
  startHours?: number
  /**
   * @format int32
   * @min 0
   * @max 59
   */
  startMinutes?: number
}

export interface UpdateCargoBookingRowMiscPropsRequest {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date */
  startDate?: string
  /**
   * @format int32
   * @min 0
   * @max 23
   */
  startHours?: number
  /**
   * @format int32
   * @min 0
   * @max 59
   */
  startMinutes?: number
}

export interface UpdateCargoBookingRowPassengerPropsRequest {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
}

export interface UpdateCargoBookingRowVehiclePropsRequest {
  /**
   * amount of vehicles - only allowed value is 1
   * @format int32
   * @min 1
   * @max 1
   */
  categoryQuantity: number
  registrationNumbers?: UpdateCargoRegistrationNumberRequest[]
  cargoDescriptions?: UpdateCargoDescriptionRequest[]
  imdgs?: UpdateCargoImdgRequest[]
  makeModels?: UpdateCargoBookingVehicleMakeModelRequest[]
  /**
   * the weight of the load
   * @max 99999999.99
   * @exclusiveMax false
   */
  loadWeight?: number
  /**
   * the weight of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  tareWeight?: number
  /**
   * the length of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * the width of the vehicle
   * @max 999.99
   * @exclusiveMax false
   */
  width?: number
  /**
   * the height of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  height?: number
  /**
   * the amount of drivers
   * @format int32
   * @max 99999
   */
  driverCount?: number
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeConsignee?: string
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeShipper?: string
  /**
   * consignee information
   * @minLength 0
   * @maxLength 1000
   */
  consignee?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  consigneeCountryCode?: string
  /**
   * shipper information
   * @minLength 0
   * @maxLength 1000
   */
  shipper?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  shipperCountryCode?: string
  /**
   * consignment note
   * @minLength 0
   * @maxLength 10
   */
  consignmentNote?: string
  /**
   * temperature
   * @minLength 0
   * @maxLength 10
   */
  temperature?: string
  /**
   * notifier information
   * @minLength 0
   * @maxLength 1000
   */
  notifier?: string
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeNotifier?: string
  rowItems?: CargoBookingRowItem[]
}

/** Object representing Vehicle Make Model */
export interface UpdateCargoBookingVehicleMakeModelRequest {
  /**
   * @minLength 0
   * @maxLength 20
   */
  vehicleMakeModelCode?: string
}

/** Object representing Cargo description object */
export interface UpdateCargoDescriptionRequest {
  /**
   * row number in the backend
   * @format int32
   */
  descriptionRowNumber?: number
  /**
   * the quantity of the items
   * @max 999999999999.99
   * @exclusiveMax false
   */
  quantity?: number
  /**
   * the type code
   * @minLength 0
   * @maxLength 10
   */
  cargoUnitTypeCode?: string
  /**
   * description
   * @minLength 0
   * @maxLength 1000
   */
  cargoDescription: string
  /**
   * gross weight
   * @max 9999999999.99
   * @exclusiveMax false
   */
  grossWeight?: number
  /**
   * net weight
   * @max 9999999999.99
   * @exclusiveMax false
   */
  netWeight?: number
  /**
   * customs code
   * @minLength 0
   * @maxLength 10
   */
  customsCode?: string
  /**
   * total monetary value of the goods
   * @min 0
   * @exclusiveMin false
   * @max 1000000000000
   * @exclusiveMax false
   */
  totalMonetaryValue?: number
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  originCountryExtCode?: string
  /** free circulation flag */
  inFreeCirculation?: boolean
  /** in transit flag */
  inTransit?: boolean
  /** from bonded warehouse flag */
  fromBondedWarehouse?: boolean
}

export interface UpdateCargoExtraBookingRowRequest {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  rowNotes?: UpdateCargoExtraRowNoteRequest[]
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** @format date */
  startDate?: string
  /**
   * @format int32
   * @min 0
   * @max 23
   */
  startHours?: number
  /**
   * @format int32
   * @min 0
   * @max 59
   */
  startMinutes?: number
}

export interface UpdateCargoExtraRowNoteRequest {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * @minLength 0
   * @maxLength 10
   */
  noteType?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  noteData?: string
}

/** Object representing Cargo IMDG object */
export interface UpdateCargoImdgRequest {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  imdgRowNumber?: number
  /**
   * class code
   * @minLength 0
   * @maxLength 10
   */
  classCode: string
  /**
   * UN number code
   * @minLength 0
   * @maxLength 10
   */
  unNumberCode: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  unNameRowNumber: number
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  emsAndPackagingGroupRowNumber: number
  /**
   * weight
   * @max 99999999.99
   * @exclusiveMax false
   */
  weight?: number
  /**
   * net weight
   * @max 99999999.99
   * @exclusiveMax false
   */
  netWeight?: number
  /**
   * flash point
   * @format int32
   * @max 99999
   */
  flashPoint?: number
  /**
   * the note
   * @minLength 0
   * @maxLength 1000
   */
  note?: string
  /** marine pollutant flag */
  marinePollutant?: boolean
  /** limited quantity flag */
  limitedQuantity?: boolean
}

export interface UpdateCargoJourneyBookingRowRequest {
  connectedBookingRows?: UpdateCargoJourneyConnectedBookingRowRequest[]
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  vehicleProperties?: UpdateCargoBookingRowVehiclePropsRequest
  cabinProperties?: UpdateCargoBookingRowCabinPropsRequest
  passengerProperties?: UpdateCargoBookingRowPassengerPropsRequest
  miscProperties?: UpdateCargoBookingRowMiscPropsRequest
  mealProperties?: UpdateCargoBookingRowMealPropsRequest
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  externalIds?: ExternalId[]
}

export interface UpdateCargoJourneyConnectedBookingRowRequest {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  vehicleProperties?: UpdateCargoBookingRowVehiclePropsRequest
  cabinProperties?: UpdateCargoBookingRowCabinPropsRequest
  passengerProperties?: UpdateCargoBookingRowPassengerPropsRequest
  miscProperties?: UpdateCargoBookingRowMiscPropsRequest
  mealProperties?: UpdateCargoBookingRowMealPropsRequest
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  externalIds?: ExternalId[]
}

export interface UpdateCargoJourneyRowRequest {
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode: string
  /** Journey code object for update requests */
  journeyCode?: JourneyCodeFieldUpdateMandatory
  journeyBookingRows?: UpdateCargoJourneyBookingRowRequest[]
}

export interface UpdateCargoNameListRowRequest {
  /**
   * reference number
   * @minLength 0
   * @maxLength 10
   */
  referenceNumber?: string
  /**
   * qr code in text format
   * @minLength 0
   * @maxLength 2000
   */
  qrCodeText?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * guest code
   * @minLength 0
   * @maxLength 10
   */
  guestCode?: string
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * middle name
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName?: string
  /**
   * The title code
   * @minLength 0
   * @maxLength 5
   */
  titleCode?: string
  /**
   * passenger's day of birth
   * @format date
   */
  dateOfBirth?: string
  /**
   * passenger's birth place
   * @minLength 0
   * @maxLength 50
   */
  birthPlace?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  nationalityExtCode?: string
  /**
   * gender code
   * @minLength 0
   * @maxLength 1
   */
  genderCode?: string
  /** disability flag */
  disability?: boolean
  /**
   * The email address
   * @minLength 0
   * @maxLength 100
   */
  email?: string
  /**
   * The mobile number
   * @minLength 0
   * @maxLength 50
   */
  mobile?: string
  /**
   * The passport number
   * @minLength 0
   * @maxLength 30
   */
  passportNumber?: string
  /**
   * passenger's passport expiry date
   * @format date
   */
  passportExpiryDate?: string
  /**
   * passenger's passport issue date
   * @format date
   */
  passportIssueDate?: string
  /**
   * travel document type code
   * @minLength 0
   * @maxLength 10
   */
  travelDocumentTypeCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  travelDocumentCountryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  travelDocumentStateCode?: string
  /**
   * passenger's visa number
   * @minLength 0
   * @maxLength 20
   */
  visaNumber?: string
  /**
   * disability note
   * @minLength 0
   * @maxLength 1000
   */
  disabilityNote?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** group leader flag */
  groupLeader?: boolean
  /**
   * The guest type external code
   * @minLength 0
   * @maxLength 50
   */
  guestTypeCode?: string
  /** assistance flag */
  assistance?: boolean
  /** assistance approved flag */
  assistanceApproved?: boolean
  /**
   * contact number in destination
   * @minLength 0
   * @maxLength 50
   */
  destinationContactNumber?: string
  /** email confirmation flag */
  mailConfirmation?: boolean
  /** sms confirmation flag */
  smsConfirmation?: boolean
}

/** Object representing Cargo registration number */
export interface UpdateCargoRegistrationNumberRequest {
  primary?: boolean
  /**
   * Registration plate number
   * @minLength 0
   * @maxLength 50
   */
  registrationNumberCode: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  registrationStateCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  registrationCountryCode?: string
  /** This contains information about the driver. Only one of name and name list row number is populated.  */
  driverInfo?: DriverInfo
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @format int32
   * @max 99999
   */
  driversNameListRowNumber?: number
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @minLength 0
   * @maxLength 50
   */
  driverNote?: string
}

export interface Error500 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error500RequiredAction
}

export interface ExistingCustomerSubLogin {
  /**
   * @minLength 1
   * @maxLength 10
   */
  emailTemplateCode: string
}

export interface NewCargoCustomerMeansOfContact {
  /**
   * @minLength 1
   * @maxLength 5
   */
  meansOfContactTypeCode: string
  /**
   * @minLength 1
   * @maxLength 50
   */
  value: string
  /**
   * @minLength 1
   * @maxLength 1000
   */
  note?: string
  allowContact: boolean
}

export interface NewCustomerSubLogin {
  /**
   * @minLength 1
   * @maxLength 10
   */
  emailTemplateCode: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  subLoginName: string
  /**
   * Organization Code. Deprecation Planned, we intend to migrate this to a form of External ID.
   * @minLength 1
   * @maxLength 50
   */
  orgName: string
  /**
   * @minLength 1
   * @maxLength 200
   */
  streetAddress?: string
  /**
   * @minLength 1
   * @maxLength 10
   */
  postalCode?: string
  /**
   * @minLength 1
   * @maxLength 50
   */
  postalDistrict?: string
  /**
   * @minLength 1
   * @maxLength 10
   */
  stateCode?: string
  /**
   * @minLength 1
   * @maxLength 10
   */
  countryExtCode: string
  /**
   * @minLength 1
   * @maxLength 10
   */
  languageExtCode: string
  /**
   * @minLength 1
   * @maxLength 50
   */
  defaultExtProductCode: string
  /**
   * @minLength 1
   * @maxLength 50
   */
  currencyExtCode: string
  /**
   * VAT ID. Deprecation Planned, we intend to migrate this to a form of External ID.
   * @minLength 0
   * @maxLength 50
   */
  vatId?: string
  meansOfContacts?: NewCargoCustomerMeansOfContact[]
}

export interface SendLoginOtpViaEmail {
  /**
   * @minLength 1
   * @maxLength 50
   */
  subLoginEmailAddress: string
  existingCustomer?: ExistingCustomerSubLogin
  newCustomer?: NewCustomerSubLogin
}

export interface CargoSelfServiceApiConflictReason {
  conflictReasonType: CargoSelfServiceApiConflictReasonConflictReasonType
}

export interface CargoSelfServiceApiError409 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: CargoSelfServiceApiError409RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
  conflictReason?: CargoSelfServiceApiConflictReason
}

/** Create customer sub-login request. */
export interface CreateCustomerSubLoginRequest {
  /**
   * customer login code
   * @minLength 0
   * @maxLength 50
   */
  customerSubLoginCode: string
  /**
   * @minLength 0
   * @maxLength 128
   */
  name?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  emailAddress?: string
  /**
   * The privilege group to assign to the customer sub-login.
   * @minLength 0
   * @maxLength 20
   */
  customerPrivilegeGroupCode?: string
  /** Flag which shows that customer sub-login is active or not. */
  active: boolean
  /**
   * Password to use when authenticating as the customer sub-login.
   * @minLength 1
   * @maxLength 20
   */
  password: string
}

export interface CreateCargoBookingContactDetailRequest {
  /**
   * contact type
   * @minLength 0
   * @maxLength 5
   */
  contactType?: string
  /**
   * contact info
   * @minLength 0
   * @maxLength 50
   */
  contactInfo?: string
  /** send info flag */
  sendInfo?: boolean
  /** auto sms flag */
  autoSms?: boolean
}

/** new booking object */
export interface CreateCargoBookingRequest {
  /**
   * Status of the booking. Note that booking status comes from a user-defined list and meanings may vary. See cancelled field for a Boolean status if the booking is cancelled or not.
   * @minLength 0
   * @maxLength 10
   */
  bookingStatusCode?: string
  /** flag specifying if terms and conditions have been accepted */
  isTermsAndConditionsAccepted?: boolean
  namelist?: CreateCargoNameListRowRequest[]
  bookingContacts?: CreateCargoBookingContactDetailRequest[]
  cargoJourney: CreateCargoJourneyRowRequest
  extraBookingLines?: CreateCargoExtraBookingRowRequest[]
  /**
   * title of the customer
   * @minLength 0
   * @maxLength 5
   */
  titleCode?: string
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * middle name
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName?: string
  /**
   * customer's street address
   * @minLength 0
   * @maxLength 200
   */
  streetAddress?: string
  /**
   * customer's postal code
   * @minLength 0
   * @maxLength 10
   */
  postalCode?: string
  /**
   * customer's postal district
   * @minLength 0
   * @maxLength 50
   */
  postalDistrict?: string
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  stateCode?: string
  /**
   * customer's phone area
   * @minLength 0
   * @maxLength 10
   */
  phoneArea?: string
  /**
   * customer's phone number
   * @minLength 0
   * @maxLength 50
   */
  phoneNumber?: string
  /**
   * Language code used externally
   * @minLength 0
   * @maxLength 10
   */
  languageExtCode?: string
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /**
   * promotion code of the booking
   * @minLength 0
   * @maxLength 10
   */
  promotionCode?: string
  /**
   * Currency code used externally
   * @minLength 0
   * @maxLength 50
   */
  currencyExtCode?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * booking reference
   * @minLength 0
   * @maxLength 60
   */
  bookingReference?: string
}

export interface CreateCargoBookingRowCabinPropsRequest {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
}

export interface CreateCargoBookingRowMealPropsRequest {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date */
  startDate?: string
  /**
   * @format int32
   * @min 0
   * @max 23
   */
  startHours?: number
  /**
   * @format int32
   * @min 0
   * @max 59
   */
  startMinutes?: number
}

export interface CreateCargoBookingRowMiscPropsRequest {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date */
  startDate?: string
  /**
   * @format int32
   * @min 0
   * @max 23
   */
  startHours?: number
  /**
   * @format int32
   * @min 0
   * @max 59
   */
  startMinutes?: number
}

export interface CreateCargoBookingRowPassengerPropsRequest {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
}

export interface CreateCargoBookingRowVehiclePropsRequest {
  /**
   * amount of vehicles - only allowed value is 1
   * @format int32
   * @min 1
   * @max 1
   */
  categoryQuantity: number
  registrationNumbers?: CreateCargoRegistrationNumberRequest[]
  cargoDescriptions?: CreateCargoDescriptionRequest[]
  imdgs?: CreateCargoImdgRequest[]
  makeModels?: CreateCargoBookingVehicleMakeModelRequest[]
  /**
   * the weight of the load
   * @max 99999999.99
   * @exclusiveMax false
   */
  loadWeight?: number
  /**
   * the weight of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  tareWeight?: number
  /**
   * the length of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * the width of the vehicle
   * @max 999.99
   * @exclusiveMax false
   */
  width?: number
  /**
   * the height of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  height?: number
  /**
   * the amount of drivers
   * @format int32
   * @max 99999
   */
  driverCount?: number
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeConsignee?: string
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeShipper?: string
  /**
   * consignee information
   * @minLength 0
   * @maxLength 1000
   */
  consignee?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  consigneeCountryCode?: string
  /**
   * shipper information
   * @minLength 0
   * @maxLength 1000
   */
  shipper?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  shipperCountryCode?: string
  /**
   * consignment note
   * @minLength 0
   * @maxLength 10
   */
  consignmentNote?: string
  /**
   * temperature
   * @minLength 0
   * @maxLength 10
   */
  temperature?: string
  /**
   * notifier information
   * @minLength 0
   * @maxLength 1000
   */
  notifier?: string
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeNotifier?: string
  rowItems?: CargoBookingRowItem[]
}

/** Object representing Cargo Vehicle Make Model */
export interface CreateCargoBookingVehicleMakeModelRequest {
  /**
   * @minLength 0
   * @maxLength 20
   */
  vehicleMakeModelCode?: string
}

/** Object representing Cargo description object */
export interface CreateCargoDescriptionRequest {
  /**
   * the quantity of the items
   * @max 999999999999.99
   * @exclusiveMax false
   */
  quantity?: number
  /**
   * the type code
   * @minLength 0
   * @maxLength 10
   */
  cargoUnitTypeCode?: string
  /**
   * description
   * @minLength 0
   * @maxLength 1000
   */
  cargoDescription: string
  /**
   * gross weight
   * @max 9999999999.99
   * @exclusiveMax false
   */
  grossWeight?: number
  /**
   * net weight
   * @max 9999999999.99
   * @exclusiveMax false
   */
  netWeight?: number
  /**
   * customs code
   * @minLength 0
   * @maxLength 10
   */
  customsCode?: string
  /**
   * total monetary value of the goods
   * @min 0
   * @exclusiveMin false
   * @max 1000000000000
   * @exclusiveMax false
   */
  totalMonetaryValue?: number
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  originCountryExtCode?: string
  /** free circulation flag */
  inFreeCirculation?: boolean
  /** in transit flag */
  inTransit?: boolean
  /** from bonded warehouse flag */
  fromBondedWarehouse?: boolean
}

export interface CreateCargoExtraBookingRowRequest {
  rowNotes?: UpdateCargoExtraRowNoteRequest[]
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** @format date */
  startDate?: string
  /**
   * @format int32
   * @min 0
   * @max 23
   */
  startHours?: number
  /**
   * @format int32
   * @min 0
   * @max 59
   */
  startMinutes?: number
}

/** Object representing Cargo IMDG object */
export interface CreateCargoImdgRequest {
  /**
   * class code
   * @minLength 0
   * @maxLength 10
   */
  classCode: string
  /**
   * UN number code
   * @minLength 0
   * @maxLength 10
   */
  unNumberCode: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  unNameRowNumber: number
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  emsAndPackagingGroupRowNumber: number
  /**
   * weight
   * @max 99999999.99
   * @exclusiveMax false
   */
  weight?: number
  /**
   * net weight
   * @max 99999999.99
   * @exclusiveMax false
   */
  netWeight?: number
  /**
   * flash point
   * @format int32
   * @max 99999
   */
  flashPoint?: number
  /**
   * the note
   * @minLength 0
   * @maxLength 1000
   */
  note?: string
  /** marine pollutant flag */
  marinePollutant?: boolean
  /** limited quantity flag */
  limitedQuantity?: boolean
}

export interface CreateCargoJourneyBookingRowRequest {
  connectedBookingRows?: CreateCargoJourneyConnectedBookingRowRequest[]
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode: string
  vehicleProperties?: CreateCargoBookingRowVehiclePropsRequest
  cabinProperties?: CreateCargoBookingRowCabinPropsRequest
  passengerProperties?: CreateCargoBookingRowPassengerPropsRequest
  miscProperties?: CreateCargoBookingRowMiscPropsRequest
  mealProperties?: CreateCargoBookingRowMealPropsRequest
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  externalIds?: ExternalId[]
}

export interface CreateCargoJourneyConnectedBookingRowRequest {
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode: string
  vehicleProperties?: CreateCargoBookingRowVehiclePropsRequest
  cabinProperties?: CreateCargoBookingRowCabinPropsRequest
  passengerProperties?: CreateCargoBookingRowPassengerPropsRequest
  miscProperties?: CreateCargoBookingRowMiscPropsRequest
  mealProperties?: CreateCargoBookingRowMealPropsRequest
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  externalIds?: ExternalId[]
}

export interface CreateCargoJourneyRowRequest {
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /** optional promotion code used for the journey */
  promotionCode?: string
  journeyBookingRows: CreateCargoJourneyBookingRowRequest[]
}

export interface CreateCargoNameListRowRequest {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * guest code
   * @minLength 0
   * @maxLength 10
   */
  guestCode?: string
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * middle name
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName?: string
  /**
   * The title code
   * @minLength 0
   * @maxLength 5
   */
  titleCode?: string
  /**
   * passenger's day of birth
   * @format date
   */
  dateOfBirth?: string
  /**
   * passenger's birth place
   * @minLength 0
   * @maxLength 50
   */
  birthPlace?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  nationalityExtCode?: string
  /**
   * gender code
   * @minLength 0
   * @maxLength 1
   */
  genderCode?: string
  /** disability flag */
  disability?: boolean
  /**
   * The email address
   * @minLength 0
   * @maxLength 100
   */
  email?: string
  /**
   * The mobile number
   * @minLength 0
   * @maxLength 50
   */
  mobile?: string
  /**
   * The passport number
   * @minLength 0
   * @maxLength 30
   */
  passportNumber?: string
  /**
   * passenger's passport expiry date
   * @format date
   */
  passportExpiryDate?: string
  /**
   * passenger's passport issue date
   * @format date
   */
  passportIssueDate?: string
  /**
   * travel document type code
   * @minLength 0
   * @maxLength 10
   */
  travelDocumentTypeCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  travelDocumentCountryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  travelDocumentStateCode?: string
  /**
   * passenger's visa number
   * @minLength 0
   * @maxLength 20
   */
  visaNumber?: string
  /**
   * disability note
   * @minLength 0
   * @maxLength 1000
   */
  disabilityNote?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** group leader flag */
  groupLeader?: boolean
  /**
   * The guest type external code
   * @minLength 0
   * @maxLength 50
   */
  guestTypeCode?: string
  /** assistance flag */
  assistance?: boolean
  /** assistance approved flag */
  assistanceApproved?: boolean
  /**
   * contact number in destination
   * @minLength 0
   * @maxLength 50
   */
  destinationContactNumber?: string
  /** email confirmation flag */
  mailConfirmation?: boolean
  /** sms confirmation flag */
  smsConfirmation?: boolean
}

/** Object representing Cargo registration number */
export interface CreateCargoRegistrationNumberRequest {
  primary?: boolean
  /**
   * Registration plate number
   * @minLength 0
   * @maxLength 50
   */
  registrationNumberCode: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  registrationStateCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  registrationCountryCode?: string
  /** This contains information about the driver. Only one of name and name list row number is populated.  */
  driverInfo?: DriverInfo
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @format int32
   * @max 99999
   */
  driversNameListRowNumber?: number
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @minLength 0
   * @maxLength 50
   */
  driverNote?: string
}

export interface CargoExtSearchResult {
  /**
   * Internal ID for the main vehicle row. Will usually be a integer, but may be bigger than allowed for integer types so presented as string.
   * @minLength 0
   * @maxLength 28
   */
  id: string
  /**
   * Booking Code
   * @minLength 0
   * @maxLength 10
   */
  bookingCode: string
  /**
   * Code for the booking status on that booking
   * @minLength 0
   * @maxLength 10
   */
  bookingStatusCode: string
  /**
   * Code for the booking status on that booking row
   * @minLength 0
   * @maxLength 10
   */
  rowStatusCode: string
  /** True if the main vehicle row is on a waiting list, false otherwise */
  onWaitlist: boolean
  /** True if the booking is cancelled, false otherwise */
  isCancelled: boolean
  /** True if the main vehicle row is checked in, false otherwise */
  isCheckedIn: boolean
  /** True if vehicle has been boarded, false otherwise. */
  isBoarded?: boolean
  /** True if vehicle has been discharged, false otherwise. */
  isDischarged?: boolean
  /** departure code for the departure for the main vehicle row */
  departureCode: string
  /**
   * journey (route) code for the departure for the main vehicle row
   * @minLength 0
   * @maxLength 10
   */
  journeyCode: string
  /**
   * The planned date and time of the departure (local time for the departure port)
   * @format date-time
   */
  departureTime: string
  /**
   * The planned date and time of the arrival (local time for the arrival port)
   * @format date-time
   */
  arrivalTime: string
  /**
   * code for the vessel to be used
   * @minLength 0
   * @maxLength 10
   */
  vesselCode: string
  /**
   * name of the vessel to be used
   * @minLength 0
   * @maxLength 50
   */
  vesselName?: string
  /**
   * code for the customer who made the booking, if made by a registered customer
   * @minLength 0
   * @maxLength 10
   */
  customerCode?: string
  /**
   * name of the customer who made the booking, if made by a registered customer
   * @minLength 0
   * @maxLength 50
   */
  customerName?: string
  /**
   * current balance for the booking this vehicle is part of.
   * @format double
   * @min -1000000000000000000
   * @exclusiveMin false
   * @max 1000000000000000000
   * @exclusiveMax false
   */
  bookingBalance?: number
  /**
   * total price for the booking this vehicle is part of.
   * @format double
   * @min -1000000000000000000
   * @exclusiveMin false
   * @max 1000000000000000000
   * @exclusiveMax false
   */
  bookingPrice?: number
  /** True if the booking is currently open by another user or system. */
  bookingIsOpen?: boolean
  /**
   * date and time when booking was last updated.
   * @format date-time
   */
  bookingLastChanged?: string
  /**
   * ID of the sales channel on which this booking was made
   * @minLength 0
   * @maxLength 10
   */
  salesChannelCode?: string
  /** additional information about cargo */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  nameList?: NameListItem[]
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortExtCode: string
  /**
   * translated port name used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortExtName?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortExtCode: string
  /**
   * translated port name used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortExtName?: string
  vehicleCategory: CargoExtVehicleSummary
}

export interface CargoExtSearchResultsCollection {
  _links?: CollectionLinks
  _meta?: CollectionMeta
  results?: CargoExtSearchResult[]
}

export interface CargoExtVehicleSummary {
  /**
   * quantity booked on the main vehicle row
   * @format int64
   * @min 1
   * @max 99999999999999
   */
  quantity: number
  /**
   * code for the category specification booked as the vehicle
   * @minLength 0
   * @maxLength 5
   */
  specificationCode?: string
  /**
   * name the category specification booked as the vehicle
   * @minLength 0
   * @maxLength 80
   */
  specificationName?: string
  /**
   * specification quantity booked on the main vehicle row
   * @format int64
   * @min 1
   * @max 99999999999999
   */
  specificationQuantity?: number
  /** primary registration number for the vehicle row */
  primaryRegNums?: VehicleRegistrationInfo[]
  /** list of secondary registration numbers for the vehicle row */
  secondaryRegNums?: VehicleRegistrationInfo[]
  /**
   * number of drivers specified for the vehicle row
   * @format int32
   * @min 0
   * @max 99999
   */
  numberDrivers?: number
  /**
   * length of the vehicle
   * @format double
   * @min 0
   * @exclusiveMin false
   * @max 9999999999
   * @exclusiveMax false
   */
  length?: number
  /**
   * width of the vehicle
   * @format double
   * @min 0
   * @exclusiveMin false
   * @max 99999
   * @exclusiveMax false
   */
  width?: number
  /**
   * height of the vehicle
   * @format double
   * @min 0
   * @exclusiveMin false
   * @max 9999999999
   * @exclusiveMax false
   */
  height?: number
  /**
   * weight of the unloaded vehicle
   * @format double
   * @min 0
   * @exclusiveMin false
   * @max 9999999999
   * @exclusiveMax false
   */
  tarWeight?: number
  /**
   * weight of the vehicle
   * @format double
   * @min 0
   * @exclusiveMin false
   * @max 9999999999
   * @exclusiveMax false
   */
  loadWeight?: number
  /**
   * weight of the vehicle
   * @format double
   * @min 0
   * @exclusiveMin false
   * @max 9999999999
   * @exclusiveMax false
   */
  totalWeight?: number
  /** True if the vehicle has any hazardous goods (IMDG) registered, false otherwise */
  hasHazardousGoods: boolean
  vehicleType?: CargoExtVehicleSummaryVehicleType
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
}

export interface CollectionLinks {
  self?: Link
  first?: Link
  next?: Link
  prev?: Link
  last?: Link
}

export interface CollectionMeta {
  /**
   * Number of objects returned in collection
   * @format int64
   */
  totalCount?: number
  /**
   * Current count of objects returned
   * @format int32
   */
  currentCount?: number
  /**
   * Number of items per page
   * @format int32
   */
  pageSize?: number
  /**
   * Number of pages returned in collection
   * @format int32
   */
  totalPages?: number
  /**
   * Current page number
   * @format int32
   */
  currentPage?: number
}

/** Container for name list item representation */
export interface NameListItem {
  name?: string
  givenName?: string
  middleName?: string
  phone?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  gender?: NameListItemGender
}

/** Container for vehicle registration info */
export interface VehicleRegistrationInfo {
  /**
   * The actual registration number
   * @minLength 0
   * @maxLength 50
   */
  regNum?: string
}

export interface SearchBookingsCriteria {
  /**
   * booking code in carres
   * @minLength 0
   * @maxLength 10
   */
  bookingCode?: string
  /**
   * The actual registration number
   * @minLength 0
   * @maxLength 50
   */
  registrationNumber?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortExtCode?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortExtCode?: string
  'checked-in'?: boolean
  boarded?: boolean
  discharged?: boolean
  freeTextSearch?: SearchBookingsCriteriaFreeTextSearch
}

export interface SearchBookingsCriteriaFreeTextSearch {
  /** search string */
  text?: string
}

export interface CargoAvailabilityAttachedRowsCabin {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityAttachedRowsCabinAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityAttachedRowsCabinRecordType
  /** alternative category flag */
  isAlternativeCategory?: boolean
  /** describes if the row is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  /**
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  berthQuantity?: number
}

export interface CargoAvailabilityAttachedRowsMeal {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityAttachedRowsMealAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityAttachedRowsMealRecordType
  /** alternative category flag */
  isAlternativeCategory?: boolean
  /** describes if the row is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startTime?: string
}

export interface CargoAvailabilityAttachedRowsMisc {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityAttachedRowsMiscAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityAttachedRowsMiscRecordType
  /** alternative category flag */
  isAlternativeCategory?: boolean
  /** describes if the row is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startTime?: string
}

export interface CargoAvailabilityAttachedRowsPassenger {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityAttachedRowsPassengerAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityAttachedRowsPassengerRecordType
  /** alternative category flag */
  isAlternativeCategory?: boolean
  /** describes if the row is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
}

export interface CargoAvailabilityAttachedRowsVehicle {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityAttachedRowsVehicleAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityAttachedRowsVehicleRecordType
  /** alternative category flag */
  isAlternativeCategory?: boolean
  /** describes if the row is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  /**
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * available length on cardeck
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  availableLength?: number
}

export interface CargoAvailabilityAttachedRowsWrapper {
  attachedItemType?: CargoAvailabilityAttachedRowsWrapperAttachedItemType
  attachedVehicleProperties?: CargoAvailabilityAttachedRowsVehicle
  attachedCabinProperties?: CargoAvailabilityAttachedRowsCabin
  attachedMealProperties?: CargoAvailabilityAttachedRowsMeal
  attachedMiscProperties?: CargoAvailabilityAttachedRowsMisc
  attachedPassengerProperties?: CargoAvailabilityAttachedRowsPassenger
}

export interface CargoAvailabilityCabin {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityCabinAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityCabinRecordType
  /** describes if the is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  upsellRows?: CargoAvailabilityUpsellRowsWrapper[]
  /**
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  berthQuantity?: number
}

export interface CargoAvailabilityCabins {
  cabin?: CargoAvailabilityCabin[]
}

export interface CargoAvailabilityMeal {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityMealAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityMealRecordType
  /** describes if the is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  upsellRows?: CargoAvailabilityUpsellRowsWrapper[]
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startTime?: string
}

export interface CargoAvailabilityMeals {
  meal?: CargoAvailabilityMeal[]
}

export interface CargoAvailabilityMisc {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityMiscAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityMiscRecordType
  /** describes if the is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  upsellRows?: CargoAvailabilityUpsellRowsWrapper[]
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startTime?: string
}

export interface CargoAvailabilityMiscs {
  misc?: CargoAvailabilityMisc[]
}

export interface CargoAvailabilityPassenger {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityPassengerAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityPassengerRecordType
  /** describes if the is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  upsellRows?: CargoAvailabilityUpsellRowsWrapper[]
}

export interface CargoAvailabilityPassengers {
  passenger?: CargoAvailabilityPassenger[]
}

export interface CargoAvailabilitySearchResult {
  /**
   * journey code in carres
   * @minLength 0
   * @maxLength 10
   */
  journeyCode?: string
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortExtCode?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortExtCode?: string
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /**
   * vessel code used in carres
   * @minLength 0
   * @maxLength 10
   */
  vesselCode?: string
  vehicleAvailability?: CargoAvailabilityVehicles
  passengersAvailability?: CargoAvailabilityPassengers
  cabinsAvailability?: CargoAvailabilityCabins
  mealsAvailability?: CargoAvailabilityMeals
  miscAvailability?: CargoAvailabilityMiscs
}

export interface CargoAvailabilityUpsellRowsCabin {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityUpsellRowsCabinAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityUpsellRowsCabinRecordType
  /** quantity flag */
  quantitySameAsCategoryQuantity?: boolean
  /** default upsell flag */
  upsellDefault?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  /**
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  berthQuantity?: number
}

export interface CargoAvailabilityUpsellRowsMeal {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityUpsellRowsMealAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityUpsellRowsMealRecordType
  /** quantity flag */
  quantitySameAsCategoryQuantity?: boolean
  /** default upsell flag */
  upsellDefault?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startTime?: string
}

export interface CargoAvailabilityUpsellRowsMisc {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityUpsellRowsMiscAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityUpsellRowsMiscRecordType
  /** quantity flag */
  quantitySameAsCategoryQuantity?: boolean
  /** default upsell flag */
  upsellDefault?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startTime?: string
}

export interface CargoAvailabilityUpsellRowsPassenger {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityUpsellRowsPassengerAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityUpsellRowsPassengerRecordType
  /** quantity flag */
  quantitySameAsCategoryQuantity?: boolean
  /** default upsell flag */
  upsellDefault?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
}

export interface CargoAvailabilityUpsellRowsVehicle {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityUpsellRowsVehicleAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityUpsellRowsVehicleRecordType
  /** quantity flag */
  quantitySameAsCategoryQuantity?: boolean
  /** default upsell flag */
  upsellDefault?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  /**
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * available length on cardeck
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  availableLength?: number
}

/** object containing the type of the upsell row and the details under its corresponding properties section */
export interface CargoAvailabilityUpsellRowsWrapper {
  upsellItemType?: CargoAvailabilityUpsellRowsWrapperUpsellItemType
  upsellVehicleProperties?: CargoAvailabilityUpsellRowsVehicle
  upsellCabinProperties?: CargoAvailabilityUpsellRowsCabin
  upsellMealProperties?: CargoAvailabilityUpsellRowsMeal
  upsellMiscProperties?: CargoAvailabilityUpsellRowsMisc
  upsellPassengerProperties?: CargoAvailabilityUpsellRowsPassenger
}

export interface CargoAvailabilityVehicle {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * category specification quantity
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  categorySpecificationQuantity?: number
  /**
   * available amount
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  availableAmount?: number
  availableStatusCode?: CargoAvailabilityVehicleAvailableStatusCode
  /**
   * status note
   * @minLength 0
   * @maxLength 2000
   */
  availableStatusNote?: string
  /**
   * the row price of the item
   * @min 0
   * @exclusiveMin false
   * @max 10000000000000000
   * @exclusiveMax false
   */
  displayRowPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  recordType?: CargoAvailabilityVehicleRecordType
  /** describes if the is generated by automatic procedure or rule */
  isAutoGenerated?: boolean
  attachedRows?: CargoAvailabilityAttachedRowsWrapper[]
  upsellRows?: CargoAvailabilityUpsellRowsWrapper[]
  /**
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * available length on cardeck
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  availableLength?: number
}

export interface CargoAvailabilityVehicles {
  vehicle?: CargoAvailabilityVehicle[]
}

/** object containing detailed fields for availability search for cabins on board */
export interface CargoAvailabilityRequestBodyCabinsProperties {
  /** flag describing if the availability group is included to the availability search or not. */
  included: boolean
  /**
   * amount included to the availability search
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  quantity?: number
  /** flag describing if spec options shall be examined */
  expandSpecifications?: boolean
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  specificationQuantity?: number
}

/** object containing detailed fields for availability search for meals on board */
export interface CargoAvailabilityRequestBodyMealsProperties {
  /** flag describing if the availability group is included to the availability search or not. */
  included: boolean
  /**
   * amount included to the availability search
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  quantity?: number
  /** flag describing if spec options shall be examined */
  expandSpecifications?: boolean
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  specificationQuantity?: number
}

/** object containing detailed fields for availability search for misc items */
export interface CargoAvailabilityRequestBodyMiscProperties {
  /** flag describing if the availability group is included to the availability search or not. */
  included: boolean
  /**
   * amount included to the availability search
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  quantity?: number
  /** flag describing if spec options shall be examined */
  expandSpecifications?: boolean
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  specificationQuantity?: number
}

/** object containing detailed fields for availability search for passengers */
export interface CargoAvailabilityRequestBodyPassengerProperties {
  /** flag describing if the availability group is included to the availability search or not. */
  included: boolean
  /**
   * amount included to the availability search
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  quantity?: number
  /** flag describing if spec options shall be examined */
  expandSpecifications?: boolean
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  specificationQuantity?: number
}

/** object containing detailed fields for availability search for vehicles */
export interface CargoAvailabilityRequestBodyVehicleProperties {
  /** flag describing if trailers are included to the availability search */
  includeTrailers: boolean
  /** flag describing if trucks are included to the availability search */
  includeTrucks: boolean
  /** flag describing if driverless cars are included to the availability search */
  includeDriverless: boolean
  /** flag describing if only cargo categories are included to the availability search */
  cargoCategoriesOnly: boolean
  /**
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /** flag describing if the availability group is included to the availability search or not. */
  included: boolean
  /**
   * amount included to the availability search
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  quantity?: number
  /** flag describing if spec options shall be examined */
  expandSpecifications?: boolean
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  specificationQuantity?: number
}

/** availability search parameters */
export interface CreateCargoAvailabilityRequest {
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode: string
  /**
   * Currency code used externally
   * @minLength 0
   * @maxLength 50
   */
  currencyExtCode?: string
  /**
   * booking code in carres
   * @minLength 0
   * @maxLength 10
   */
  bookingCode?: string
  /** if true then include sold out row, if not then only availalbe */
  includeSoldOut?: boolean
  /** if true then include also not possible rows. This is only intended for debugging so you can see why (e.g. missing price) */
  includeNotPossible?: boolean
  /** object containing detailed fields for availability search for vehicles */
  vehicleAvailability?: CargoAvailabilityRequestBodyVehicleProperties
  /** object containing detailed fields for availability search for misc items */
  miscAvailability?: CargoAvailabilityRequestBodyMiscProperties
  /** object containing detailed fields for availability search for passengers */
  passengerAvailability?: CargoAvailabilityRequestBodyPassengerProperties
  /** object containing detailed fields for availability search for cabins on board */
  cabinAvailability?: CargoAvailabilityRequestBodyCabinsProperties
  /** object containing detailed fields for availability search for meals on board */
  mealAvailability?: CargoAvailabilityRequestBodyMealsProperties
}

export interface TokenResponse {
  /** Token (to be submitted as X-Carus-Auth header) for this entity. */
  carusAuthToken?: string
  /**
   * Number of seconds until token expires.
   * @format int32
   */
  expiresIn?: number
  /** Token to be used when refreshing this token */
  refreshToken?: string
  /** Token type. Possible values are - X-Carus-Auth - to be submitted as X-Carus-Auth header  */
  tokenType?: TokenResponseTokenType
}

export interface TokenRefreshRequest {
  /** The orignal token to refresh */
  token?: string
  /** the refresh token */
  refreshToken?: string
}

export interface TokenCreationRequest {
  /** ID for the entity */
  code?: string
  /** password for the entity */
  password?: string
}

export interface CustomerSubLoginOneTimePassword {
  /**
   * One time password.
   * @minLength 1
   * @maxLength 50
   */
  oneTimePassword: string
}

/** Allow Contact object for update requests */
export interface AllowContactFieldUpdateMandatory {
  /** allow contact flag */
  value: boolean
}

/** an array of row number */
export interface ArrayOfRowNumber {
  rowNumbers?: number[]
}

/** Automatic SMS sending flag for update requests */
export interface AutoSmsFieldUpdateMandatory {
  /** Automatic SMS sending flag */
  value: boolean
}

/** Care of Address object for update requests */
export interface CareOfAddressFieldUpdate {
  /**
   * The care of address
   * @minLength 0
   * @maxLength 50
   */
  value?: string
}

/** Contact Code object for update requests */
export interface ContactCodeFieldUpdate {
  /**
   * Contact Code
   * @minLength 0
   * @maxLength 5
   */
  value?: string
}

/** Contact Information object for update requests */
export interface ContactInformationFieldUpdate {
  /**
   * Contact Information
   * @minLength 0
   * @maxLength 50
   */
  value?: string
}

/** Object representing new means of contact */
export interface CreateCargoCustomersMeanOfContactRequest {
  /**
   * Contact Code
   * @minLength 0
   * @maxLength 5
   */
  contactCode: string
  /**
   * Contact Information
   * @minLength 0
   * @maxLength 50
   */
  contactInformation?: string
  /** allow contact flag */
  allowContact?: boolean
  /** Automatic SMS sending flag */
  autoSMS?: boolean
}

/** External Country Code object for update requests */
export interface ExternalCountryCodeFieldUpdateMandatory {
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  value: string
}

/** External Language Code object for update requests */
export interface ExternalLanguageCodeFieldUpdateMandatory {
  /**
   * Language code used externally
   * @minLength 0
   * @maxLength 10
   */
  value: string
}

/** update object for lastname field */
export interface LastNameFieldUpdateMandatory {
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  value: string
}

/** Phone Code object for update requests */
export interface PhoneCodeFieldUpdate {
  /**
   * Phone area code
   * @minLength 0
   * @maxLength 10
   */
  value?: string
}

/** Phone Number object for update requests */
export interface PhoneNumberFieldUpdate {
  /**
   * The phone number
   * @minLength 0
   * @maxLength 50
   */
  value?: string
}

/** Postal Code object for update requests */
export interface PostalCodeFieldUpdate {
  /**
   * The postal code
   * @minLength 0
   * @maxLength 10
   */
  value?: string
}

/** Postal District object for update requests */
export interface PostalDistrictFieldUpdate {
  /**
   * The postal district
   * @minLength 0
   * @maxLength 50
   */
  value?: string
}

/** State Code object for update requests */
export interface StateCodeFieldUpdate {
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  value?: string
}

/** Street Address object for update requests */
export interface StreetAddressFieldUpdate {
  /**
   * The street address
   * @minLength 0
   * @maxLength 200
   */
  value?: string
}

/** modify customer object */
export interface UpdateCargoCustomerRequest {
  /** update object for lastname field */
  name?: LastNameFieldUpdateMandatory
  /** Street Address object for update requests */
  streetAddress?: StreetAddressFieldUpdate
  /** Postal Code object for update requests */
  postalCode?: PostalCodeFieldUpdate
  /** Postal District object for update requests */
  postalDistrict?: PostalDistrictFieldUpdate
  /** External Country Code object for update requests */
  countryExtCode?: ExternalCountryCodeFieldUpdateMandatory
  /** State Code object for update requests */
  stateCode?: StateCodeFieldUpdate
  /** Visiting Address object for update requests */
  visitingAddress?: VisitingAddressFieldUpdate
  /** Care of Address object for update requests */
  careOfAddress?: CareOfAddressFieldUpdate
  /** External Language Code object for update requests */
  languageExtCode?: ExternalLanguageCodeFieldUpdateMandatory
  /** Phone Code object for update requests */
  phoneAreaCode?: PhoneCodeFieldUpdate
  /** Phone Number object for update requests */
  phoneNumber?: PhoneNumberFieldUpdate
  /** Allow Contact object for update requests */
  allowContact?: AllowContactFieldUpdateMandatory
  /** Cargo Customer Means of contact object for update method */
  meansOfContact?: UpdateCargoCustomersMeansOfContactRequest
}

/** Cargo Customer Means of Contact object */
export interface UpdateCargoCustomersMeanOfContactRequest {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber: number
  /** Contact Code object for update requests */
  contactCode?: ContactCodeFieldUpdate
  /** Contact Information object for update requests */
  contactInformation?: ContactInformationFieldUpdate
  /** Allow Contact object for update requests */
  allowContact?: AllowContactFieldUpdateMandatory
  /** Automatic SMS sending flag for update requests */
  autoSMS?: AutoSmsFieldUpdateMandatory
}

/** Cargo Customer Means of contact object for update method */
export interface UpdateCargoCustomersMeansOfContactRequest {
  /** an array of row number */
  delete?: ArrayOfRowNumber
  add?: CreateCargoCustomersMeanOfContactRequest[]
  update?: UpdateCargoCustomersMeanOfContactRequest[]
}

/** Visiting Address object for update requests */
export interface VisitingAddressFieldUpdate {
  /**
   * The visiting address
   * @minLength 0
   * @maxLength 50
   */
  value?: string
}

export interface CustomerSubLoginActiveUpdate {
  /** Flag which shows that customer sub-login is active or not. */
  value: boolean
}

export interface CustomerSubLoginEmailAddressUpdate {
  /**
   * @minLength 0
   * @maxLength 50
   */
  value?: string
}

export interface CustomerSubLoginNameUpdate {
  /**
   * @minLength 0
   * @maxLength 128
   */
  value?: string
}

export interface CustomerSubLoginPasswordUpdate {
  /**
   * Password to use when authenticating as the customer sub-login.
   * @minLength 1
   * @maxLength 20
   */
  value: string
}

export interface CustomerSubLoginPrivilegeGroupCodeUpdate {
  /**
   * The privilege group to assign to the customer sub-login.
   * @minLength 0
   * @maxLength 20
   */
  value?: string
}

/** Update customer sub-login request. */
export interface UpdateCustomerSubLoginRequest {
  name?: CustomerSubLoginNameUpdate
  emailAddress?: CustomerSubLoginEmailAddressUpdate
  password?: CustomerSubLoginPasswordUpdate
  customerPrivilegeGroupCode?: CustomerSubLoginPrivilegeGroupCodeUpdate
  active?: CustomerSubLoginActiveUpdate
}

export interface CargoAllotmentValues {
  /**
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  allottedLength?: number
  /**
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  bookedLength?: number
  /**
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  availableLength?: number
}

export interface CargoCustomerAllotment {
  allotments?: CargoLengthAllotmentSummary
  vehicleCategoryAllotments?: CargoVehicleCategoryAllotment[]
  vehicleCategoryCapacityGroups?: CargoVehicleCategoryCapacityGroupAllotment[]
  /**
   * date-time when protected space will be released.
   * @format date-time
   */
  releaseTime?: string
  /** If true you are only allowed to book within your allotment (protected space is of type closed), otherwise you can also use generally available space after you have consumed your allotment.  */
  bookingRestrictedToAllotment: boolean
}

export interface CargoLengthAllotmentSummary {
  lowSpaceAllotment?: CargoAllotmentValues
  mediumSpaceAllotment?: CargoAllotmentValues
  highSpaceAllotment?: CargoAllotmentValues
}

export interface CargoVehicleCategoryAllotment {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode: string
  /**
   * @format int32
   * @max 99999
   */
  allotment: number
  /**
   * @format int32
   * @max 999999999
   */
  booked: number
  /**
   * @format int32
   * @max 999999999
   */
  available: number
}

export interface CargoVehicleCategoryCapacityGroupAllotment {
  /**
   * @minLength 0
   * @maxLength 50
   */
  capacityGroupCode: string
  /**
   * @minLength 0
   * @maxLength 101
   */
  capacityGroupName?: string
  /**
   * @format int32
   * @max 99999
   */
  allotment: number
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding allotment field instead.
   * @format int32
   * @max 99999
   */
  assigned: number
  /**
   * @format int32
   * @max 999999999
   */
  booked: number
  /**
   * @format int32
   * @max 999999999
   */
  available: number
}

/** Update allotments request. */
export interface UpdateCargoAllotmentRequest {
  /**
   * space allotment for specific height class
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  lowSpaceAllotment?: number
  /**
   * space allotment for specific height class
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  mediumSpaceAllotment?: number
  /**
   * space allotment for specific height class
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  highSpaceAllotment?: number
  vehicleCategories?: UpdateCargoVehicleCategoryAllotment[]
  vehicleCategoryCapacityGroups?: UpdateCargoVehicleCategoryCapacityGroupAllotment[]
}

export interface UpdateCargoVehicleCategoryAllotment {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode: string
  /**
   * modified space allotment for specific height class
   * @format int32
   * @min 0
   * @max 99999
   */
  allotment: number
}

export interface UpdateCargoVehicleCategoryCapacityGroupAllotment {
  /**
   * @minLength 0
   * @maxLength 50
   */
  categoryCapacityGroupCode: string
  /**
   * modified space allotment for specific height class
   * @format int32
   * @min 0
   * @max 99999
   */
  allotment: number
}

/** Response object describing the fees of the cancelled booking */
export interface CargoBookingCancelled {
  /**
   * cancellation fee
   * @max 1000000000000
   * @exclusiveMax false
   */
  cancelFee?: number
  /**
   * Currency code used externally
   * @minLength 0
   * @maxLength 50
   */
  currencyCode?: string
  /**
   * booking balance
   * @max 1000000000000
   * @exclusiveMax false
   */
  bookingBalance?: number
}

export interface CargoImdgClass {
  /**
   * IMDG class code
   * @minLength 0
   * @maxLength 10
   */
  imdgClassCode?: string
  /**
   * IMDG class name
   * @minLength 0
   * @maxLength 50
   */
  imdgClassName?: string
}

/** An array of IMDG classes. */
export interface CargoImdgClassCollection {
  imdgClasses?: CargoImdgClass[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

export interface CargoVehicleUnit {
  /**
   * unit number of the vehicle
   * @format int32
   * @min 1
   * @max 999999999
   */
  vehicleUnitNumber?: number
  /**
   * The registration number of the vehicle
   * @minLength 0
   * @maxLength 20
   */
  registrationNumber?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * the weight of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  tareWeight?: number
  /**
   * the length of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * the width of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 999.99
   * @exclusiveMax false
   */
  width?: number
  /**
   * the height of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  height?: number
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
  /** flag describing if the unit is active or not */
  active?: boolean
}

/** An array of objects. */
export interface CargoVehicleUnitCollection {
  cargoVehicleUnits?: CargoVehicleUnit[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

/** External port object */
export interface CargoExtPort {
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  portExtCode?: string
  /**
   * Name of port
   * @minLength 0
   * @maxLength 50
   */
  portName?: string
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
}

/** A list of external ports */
export interface CargoExtPortsCollection {
  ports?: CargoExtPort[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

export interface CargoCustomerSubLogin {
  /**
   * customer login code
   * @minLength 0
   * @maxLength 50
   */
  customerSubLoginCode: string
  /**
   * @minLength 0
   * @maxLength 128
   */
  name?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  emailAddress?: string
  /**
   * The privilege group to assign to the customer sub-login.
   * @minLength 0
   * @maxLength 20
   */
  customerPrivilegeGroupCode?: string
  /** Flag which shows that customer sub-login is active or not. */
  active: boolean
}

export interface CargoLoginInformation {
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCode: string
  customerName: string
  /**
   * customer login code
   * @minLength 0
   * @maxLength 50
   */
  customerLoginCode: string
  customerSubLogin?: CargoCustomerSubLogin
  customerPrivileges?: CustomerPrivilege[]
}

/** Customer privilege */
export interface CustomerPrivilege {
  /** Can be one of the following values: - AMEND_BOOK - Amend booking - AMEND_CUS - Modify customer - CANCEL_BOOK - Cancel booking - CLOSE_DEP - Close departure - CREATE_BOOK - Create booking - MANAGE_LOGIN - Manage customer web login details - SEND_SMS - Send SMS message - UPDATE_BOKCNT - Update phone or email on booking - UPDATE_NAMES - Update names on booking - UPDATE_PROTSP - Update protected space - UPDATE_REF - Update booking row reference - UPDATE_REGNUM - Update register numbers on booking  */
  privilegeCode: string
  privilegeSettings?: CustomerPrivilegeSetting[]
}

/** Customer privilege setting */
export interface CustomerPrivilegeSetting {
  /** Customer privilege setting */
  tingCode?: CustomerPrivilegeSetting
  /** Can be one of the following values: - AMEND_BOOK MAX_MOVE_JRY - Maximum amount of hours journey can be moved - AMEND_BOOK PREVENT_3RD_CTY - Prevent set of 3rd country on booking - AMEND_BOOK PREVENT_IMDG - Prevent booking of dangerous goods  */
  settingCode: string
  dataType: CustomerPrivilegeSettingDataType
  textValue?: string
  numericValue?: number
  /** @format date-time */
  dateValue?: string
  booleanValue?: boolean
}

/** customer object */
export interface CargoCustomer {
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCode?: string
  /**
   * VAT ID. Deprecation Planned, we intend to migrate this to a form of External ID.
   * @minLength 0
   * @maxLength 50
   */
  vatId?: string
  /**
   * Organization Code. Deprecation Planned, we intend to migrate this to a form of External ID.
   * @minLength 0
   * @maxLength 50
   */
  organizationId?: string
  /**
   * Currency code used externally
   * @minLength 0
   * @maxLength 50
   */
  currencyExtCode?: string
  /**
   * CarRes' internal ID for the method of payment
   * @minLength 0
   * @maxLength 10
   */
  methodOfPaymentCode?: string
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /** Object presents means of contact information as read only information */
  mobileContact?: CargoCustomersMeansOfContactSummary
  /** Object presents means of contact information as read only information */
  emailContact?: CargoCustomersMeansOfContactSummary
  meansOfContact?: CargoCustomerMeansOfContact[]
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  name?: string
  /**
   * The street address
   * @minLength 0
   * @maxLength 200
   */
  streetAddress?: string
  /**
   * The postal code
   * @minLength 0
   * @maxLength 10
   */
  postalCode?: string
  /**
   * The postal district
   * @minLength 0
   * @maxLength 50
   */
  postalDistrict?: string
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  stateCode?: string
  /**
   * The visiting address
   * @minLength 0
   * @maxLength 50
   */
  visitingAddress?: string
  /**
   * The care of address
   * @minLength 0
   * @maxLength 50
   */
  careOfAddress?: string
  /**
   * Language code used externally
   * @minLength 0
   * @maxLength 10
   */
  languageExtCode?: string
  /**
   * Phone area code
   * @minLength 0
   * @maxLength 10
   */
  phoneAreaCode?: string
  /**
   * The phone number
   * @minLength 0
   * @maxLength 50
   */
  phoneNumber?: string
  /** allow contact flag */
  allowContact?: boolean
}

/** Object representing the means of contact */
export interface CargoCustomerMeansOfContact {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * Contact Code
   * @minLength 0
   * @maxLength 5
   */
  contactCode?: string
  /**
   * Contact Note
   * @minLength 0
   * @maxLength 1000
   */
  contactNote?: string
  /**
   * Contact Information
   * @minLength 0
   * @maxLength 50
   */
  contactInformation?: string
  /** allow contact flag */
  allowContact?: boolean
  /** Automatic SMS sending flag */
  autoSMS?: boolean
}

/** Object presents means of contact information as read only information */
export interface CargoCustomersMeansOfContactSummary {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * Contact Information
   * @minLength 0
   * @maxLength 50
   */
  contactInformation?: string
  /** allow contact flag */
  allowContact?: boolean
  /** Automatic SMS sending flag */
  autoSMS?: boolean
}

/** A list of cargo customer sub-logins. */
export interface CargoCustomerSubLoginsCollection {
  _links?: CollectionLinks
  _meta?: CollectionMeta
  sublogins?: CargoCustomerSubLogin[]
}

export interface CargoCustomerPrivilegeGroup {
  /**
   * @minLength 0
   * @maxLength 20
   */
  customerPrivilegeGroupCode: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  name?: string
}

/** A list of cargo customer sub-logins. */
export interface CargoCustomerPrivilegeGroupsCollection {
  _links?: CollectionLinks
  _meta?: CollectionMeta
  customerPrivilegeGroups?: CargoCustomerPrivilegeGroup[]
}

/** journey object */
export interface CargoJourney {
  /**
   * journey code in carres
   * @minLength 0
   * @maxLength 10
   */
  journeyCode?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortExtCode?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortExtCode?: string
}

/** An array of objects. */
export interface CargoJourneysCollection {
  cargoJourneys?: CargoJourney[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

/** Object representing the packaging options for class */
export interface CargoImdgEmsAndPackagingGroup {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  emsAndPackagingGroupRowNumber?: number
  /**
   * ems
   * @minLength 0
   * @maxLength 10
   */
  ems?: string
  /**
   * packaging group code
   * @format int32
   * @max 9
   */
  packagingGroupCode?: number
}

export interface CargoImdgUnNumberWithOptions {
  /**
   * UN number code
   * @minLength 0
   * @maxLength 10
   */
  unNumberCode?: string
  unNameOptions?: CargoUnName[]
  emsAndPackagingGroupOptions?: CargoImdgEmsAndPackagingGroup[]
}

/** An array of IMDG UN numbers. */
export interface CargoImdgUnNumberWithOptionsCollection {
  imdgUnNumbers?: CargoImdgUnNumberWithOptions[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

/** Object representing UN name. */
export interface CargoUnName {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  unNameRowNumber?: number
  /**
   * Full UN name
   * @minLength 0
   * @maxLength 200
   */
  name?: string
}

/** Gender object */
export interface CargoGender {
  /**
   * gender code
   * @minLength 0
   * @maxLength 1
   */
  genderCode?: string
  /**
   * gender name
   * @minLength 0
   * @maxLength 50
   */
  genderName?: string
}

/** A list of genders */
export interface CargoGendersCollection {
  genders?: CargoGender[]
}

export interface AllowedExternalIdTypeUses {
  /** If true, the External ID type can be connected to customers which are companies (Gender Company) */
  companyCustomer: boolean
  /** If true, the External ID type can be connected to agents */
  agent: boolean
  /** If true, the External ID type can be connected to suppliers */
  supplier: boolean
  /** If true, the External ID type can be connected to customers which are Natural Persons (Gender Male/Female) */
  naturalPersonCustomer: boolean
  /** If true, the External ID type is allowed in booking header */
  bookingHeader: boolean
  /** If true, the External ID type is allowed in namelist */
  nameListRow: boolean
  /** If true, the External ID type is allowed on booking row */
  bookingRow: boolean
  /**
   * External ID type is allowed on booking rows with categories in this group. Only relevant when bookingRow is true
   * @minLength 0
   * @maxLength 10
   */
  restrictToCategoryStructureGroupCode?: string
}

/** External id used by customers, agents, suppliers etc. */
export interface ExternalIdType {
  /**
   * Internal (CarRes) ID of the external identification type
   * @format int64
   */
  id: number
  /**
   * @minLength 0
   * @maxLength 10
   */
  externalIdTypeCode: string
  /**
   * Name of External identification type in provided language
   * @minLength 0
   * @maxLength 50
   */
  name: string
  /**
   * Default name of External identification type
   * @minLength 0
   * @maxLength 50
   */
  internalName?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  description?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  standardizedIdTypeCode?: string
  /**
   * @minLength 0
   * @maxLength 20
   */
  systemValidationCode?: string
  /** If true, the value of the type is deleted when the connected entity is anonymized */
  anonymize: boolean
  /** If true, then used as default in bookings */
  defaultInBooking: boolean
  active: boolean
  allowedUses: AllowedExternalIdTypeUses
  validation: ExternalIdTypeValidation
}

/** List of external ids */
export interface ExternalIdTypeCollection {
  _links?: CollectionLinks
  _meta?: CollectionMeta
  externalIdTypes?: ExternalIdType[]
}

export interface ExternalIdTypeValidation {
  /**
   * Minimum length for the value of the External ID type
   * @format int32
   * @min 1
   * @max 128
   */
  minLength?: number
  /**
   * Maximum length for the value of the External ID type
   * @format int32
   * @min 1
   * @max 128
   */
  maxLength?: number
  /** If true, the value of this type is allowed to include numbers */
  numbersAllowed: boolean
  /** If true, the value of this type is allowed to include letters A-Z */
  alphabeticCharactersAllowed: boolean
  /** If true, the value of this type is allowed to include other chars than A-Z */
  allCharactersAllowed: boolean
  forceToCase: ExternalIdTypeValidationForceToCase
  /**
   * The value of this type must match the regular expression
   * @minLength 0
   * @maxLength 256
   */
  regularExpression?: string
}

/** A Product object. */
export interface CargoProduct {
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /**
   * product name
   * @minLength 0
   * @maxLength 50
   */
  productName?: string
  /**
   * start date for when new bookings of the product can be done
   * @format date
   */
  startBookingDate?: string
  /**
   * end date for when new bookings of the product can be done
   * @format date
   */
  endBookingDate?: string
  /** flag describing if product has package rule */
  hasPackageRule?: boolean
  /**
   * value defines how many days before the departure bookings are allowed by minimum
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  minDaysBeforeDeparture?: number
  /**
   * value defines how many days before the departure bookings are allowed by maximum
   * @format int64
   * @min 0
   * @max 99999999999999
   */
  maxDaysBeforeDeparture?: number
  /** a list of product types the product has been mapped to */
  productTypes?: CargoProductType[]
}

/** An array of product objects */
export interface CargoProductCollection {
  products?: CargoProduct[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

/** A Product Type Object. */
export interface CargoProductType {
  /**
   * product type code
   * @minLength 0
   * @maxLength 10
   */
  productTypeCode?: string
  /**
   * name of the product type
   * @minLength 0
   * @maxLength 50
   */
  productTypeName?: string
}

/** Category defaults item */
export interface CargoCategoryDefaultItem {
  /**
   * Minimum value
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  minValue?: number
  /**
   * Maximum value
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  maxValue?: number
  /**
   * Default value
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  defaultValue?: number
  /** Category value required flag */
  isRequired?: boolean
}

/** Object holding the default values for the category */
export interface CargoCategoryDefaults {
  /**
   * Category specification default quantity
   * @min 0
   * @exclusiveMin false
   * @max 999999999999.99
   * @exclusiveMax false
   */
  categorySpecificationQuantity?: number
  /** Category defaults item */
  lengthDefaults?: CargoCategoryDefaultItem
  /** Category defaults item */
  widthDefaults?: CargoCategoryDefaultItem
  /** Category defaults item */
  heightDefaults?: CargoCategoryDefaultItem
  /** Category defaults item */
  weightDefaults?: CargoCategoryDefaultItem
}

/** Mapped names of the category */
export interface CargoCategoryNames {
  /**
   * suggested display name
   * @minLength 0
   * @maxLength 80
   */
  displayName?: string
  /**
   * short name in singular
   * @minLength 0
   * @maxLength 101
   */
  shortNameSingular?: string
  /**
   * short name in plural
   * @minLength 0
   * @maxLength 101
   */
  shortNamePlural?: string
  /**
   * long name in singular
   * @minLength 0
   * @maxLength 101
   */
  longNameSingular?: string
  /**
   * long name in singular
   * @minLength 0
   * @maxLength 101
   */
  longNamePlural?: string
  /**
   * description of the category
   * @minLength 0
   * @maxLength 1000
   */
  description?: string
}

/** object representing category specification */
export interface CargoCategorySpecification {
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * minimum amount to be booked
   * @format int32
   * @min 0
   * @max 99999
   */
  minSpecificationQuantity?: number
  /**
   * maximum amount to be booked
   * @format int32
   * @min 0
   * @max 99999
   */
  maxSpecificationQuantity?: number
  /**
   * minumum age to be allowed for age limited specification
   * @format int32
   * @min 0
   * @max 999
   */
  minAge?: number
  /**
   * maximum age to be allowed for age limited specification
   * @format int32
   * @min 0
   * @max 999
   */
  maxAge?: number
  /** Mapped names of the category specification */
  names?: CargoSpecificationNames
  /** if true specification quantity is mandatory - if false quantity is not allowed */
  specificationQuantityRequired?: boolean
}

/** An array of external categories objects */
export interface CargoExtCategoriesCollection {
  _links?: CollectionLinks
  _meta?: CollectionMeta
  extCategories?: CargoExtCategory[]
}

/** object representing the external category */
export interface CargoExtCategory {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  categoryExtCode?: string
  availabilityGroupType?: CargoExtCategoryAvailabilityGroupType
  /**
   * minimum amount to be booked
   * @format int32
   * @min 0
   * @max 99999
   */
  minCategoryQuantity?: number
  /**
   * maximum amount to be booked
   * @format int32
   * @min 0
   * @max 99999
   */
  maxCategoryQuantity?: number
  /** flag describes if the specification is required */
  specificationRequired?: boolean
  /** a list of possible specifications for the category */
  categorySpecifications?: CargoCategorySpecification[]
  categoryStructureGroups?: CargoExtCategoryStructureGroup[]
  /** Mapped names of the category */
  categoryNames?: CargoCategoryNames
  /** Object holding the default values for the category */
  defaults?: CargoCategoryDefaults
  /** Flag describing if the category is bookable */
  isBookable?: boolean
  /** Flag describing if the category is journey related */
  journey?: boolean
  /** Flag describing if the category is ashore type */
  ashore?: boolean
  /** Flag describing if the category is for extras */
  extras?: boolean
  /** Flag describing if the category is cargo type */
  cargo?: boolean
  /** Flag describing if the category is applicable for additional fees */
  fee?: boolean
  /** Flag describing if the category is taxable */
  tax?: boolean
  /** Flag describing if the vehicle is truck */
  truck?: boolean
  /** Flag describing if the vehicle is driverless */
  driverless?: boolean
  /** Flag describing if the vehicle is trailer */
  trailers?: boolean
}

/** Category structure group object */
export interface CargoExtCategoryStructureGroup {
  /**
   * Category structure group code
   * @minLength 0
   * @maxLength 10
   */
  categoryStructureGroupCode: string
  /**
   * Category structure group code used externally
   * @minLength 0
   * @maxLength 50
   */
  categoryStructureGroupExtCode: string
}

/** Mapped names of the category specification */
export interface CargoSpecificationNames {
  /**
   * suggested display name
   * @minLength 0
   * @maxLength 80
   */
  displayName?: string
  /**
   * short name in singular
   * @minLength 0
   * @maxLength 101
   */
  shortNameSingular?: string
  /**
   * short name in plural
   * @minLength 0
   * @maxLength 101
   */
  shortNamePlural?: string
  /**
   * long name in singular
   * @minLength 0
   * @maxLength 101
   */
  longNameSingular?: string
  /**
   * long name in singular
   * @minLength 0
   * @maxLength 101
   */
  longNamePlural?: string
}

/** list of customers bookings on the departure */
export interface CargoBookingSummary {
  /**
   * booking code in carres
   * @minLength 0
   * @maxLength 10
   */
  bookingCode?: string
  /**
   * booking status code
   * @minLength 0
   * @maxLength 10
   */
  statusCode?: string
  /** dangerous goods flag */
  hasDangerousGoods?: boolean
}

export interface CargoDeparture {
  /**
   * vessel name used in carres
   * @minLength 0
   * @maxLength 50
   */
  vesselName?: string
  /** @format date-time */
  IMDGDeadlineDateTime?: string
  /** flag describing if not all IMDGs have been approved yet */
  hasNotApprovedIMDGs?: boolean
  /** @format double */
  totalBookableLength?: number
  /**
   * closed date of the departure
   * @format date-time
   */
  closedDateTime?: string
  customerAllotments?: CargoCustomerAllotment
  /** list of customers bookings on the departure */
  bookings?: CargoBookingSummary[]
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /**
   * journey code in carres
   * @minLength 0
   * @maxLength 10
   */
  journeyCode?: string
  /** @format date-time */
  departureDatetime?: string
  /** @format date-time */
  arrivalDatetime?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortExtCode?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortExtCode?: string
  /**
   * vessel code used in carres
   * @minLength 0
   * @maxLength 10
   */
  vesselCode?: string
}

/** An array of Cargo Departures */
export interface CargoDepartureCollection {
  cargoDepartures?: CargoDeparture[]
}

export interface CargoAshoreBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * customer price
   * @max 1000000000000
   * @exclusiveMax false
   */
  customerPrice?: number
  /**
   * vat amount
   * @max 1000000000000
   * @exclusiveMax false
   */
  vat?: number
  /**
   * display price
   * @max 1000000000000
   * @exclusiveMax false
   */
  displayPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  /**
   * premise code
   * @minLength 0
   * @maxLength 10
   */
  premiseCode?: string
  /**
   * start date
   * @format date-time
   */
  startDate?: string
  /**
   * end date
   * @format date-time
   */
  endDate?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  availabilityGroupCode?: CargoAshoreBookingRowAvailabilityGroupCode
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** auto attached flag */
  isAutoAttached?: boolean
  names?: CargoBookingAshoreReferencedNameListRow[]
  /** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
  ashoreReferenceNumbers?: string[]
  rowItems?: CargoBookingRowItem[]
  connectedAshoreBookingRows?: CargoAshoreConnectedBookingRow[]
}

export interface CargoAshoreConnectedBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * customer price
   * @max 1000000000000
   * @exclusiveMax false
   */
  customerPrice?: number
  /**
   * vat amount
   * @max 1000000000000
   * @exclusiveMax false
   */
  vat?: number
  /**
   * display price
   * @max 1000000000000
   * @exclusiveMax false
   */
  displayPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  /**
   * premise code
   * @minLength 0
   * @maxLength 10
   */
  premiseCode?: string
  /**
   * start date
   * @format date-time
   */
  startDate?: string
  /**
   * end date
   * @format date-time
   */
  endDate?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  availabilityGroupCode?: CargoAshoreConnectedBookingRowAvailabilityGroupCode
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** auto attached flag */
  isAutoAttached?: boolean
  names?: CargoBookingAshoreReferencedNameListRow[]
  /** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
  ashoreReferenceNumbers?: string[]
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBooking {
  /**
   * title of the customer
   * @minLength 0
   * @maxLength 5
   */
  titleCode?: string
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * middle name
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName?: string
  /**
   * customer's street address
   * @minLength 0
   * @maxLength 200
   */
  streetAddress?: string
  /**
   * customer's postal code
   * @minLength 0
   * @maxLength 10
   */
  postalCode?: string
  /**
   * customer's postal district
   * @minLength 0
   * @maxLength 50
   */
  postalDistrict?: string
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  stateCode?: string
  /**
   * customer's phone area
   * @minLength 0
   * @maxLength 10
   */
  phoneArea?: string
  /**
   * customer's phone number
   * @minLength 0
   * @maxLength 50
   */
  phoneNumber?: string
  /**
   * Language code used externally
   * @minLength 0
   * @maxLength 10
   */
  languageExtCode?: string
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /**
   * promotion code of the booking
   * @minLength 0
   * @maxLength 10
   */
  promotionCode?: string
  /**
   * Currency code used externally
   * @minLength 0
   * @maxLength 50
   */
  currencyExtCode?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * booking reference
   * @minLength 0
   * @maxLength 60
   */
  bookingReference?: string
  /**
   * booking code in carres
   * @minLength 0
   * @maxLength 10
   */
  bookingCode?: string
  /**
   * booking version code to be used in modify requests
   * @min 1
   * @exclusiveMin false
   * @max 99999999999999
   * @exclusiveMax false
   */
  bookingVersionCode?: number
  /** is booking cancelled */
  isCancelled?: boolean
  /**
   * Status of the booking. Note that booking status comes from a user-defined list and meanings may vary. See cancelled field for a Boolean status if the booking is cancelled or not.
   * @minLength 0
   * @maxLength 10
   */
  bookingStatusCode?: string
  /** dangerous goods flag */
  hasDangerousGoods?: boolean
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCode?: string
  /**
   * agent code
   * @minLength 0
   * @maxLength 10
   */
  agentCode?: string
  /**
   * date and time of the booking
   * @format date-time
   */
  bookingTime?: string
  /**
   * last change time of the booking
   * @format date-time
   */
  bookingLastChangeTime?: string
  /**
   * bookings validity time
   * @format date-time
   */
  bookingValidTo?: string
  /**
   * api system name which originated this booking
   * @minLength 0
   * @maxLength 10
   */
  bookingApiSys?: string
  /**
   * Method of payment code of the booking
   * @minLength 0
   * @maxLength 10
   */
  methodOfPaymentCode?: string
  /**
   * @min 0
   * @exclusiveMin false
   * @max 1000000000000
   * @exclusiveMax false
   */
  bookingBalance?: number
  /**
   * @min 0
   * @exclusiveMin false
   * @max 1000000000000
   * @exclusiveMax false
   */
  amountToBeInvoiced?: number
  /**
   * @min 0
   * @exclusiveMin false
   * @max 1000000000000
   * @exclusiveMax false
   */
  totalAmount?: number
  /**
   * due date
   * @format date
   */
  dueDate?: string
  /**
   * deposit fee
   * @min 0
   * @exclusiveMin false
   * @max 1000000000000
   * @exclusiveMax false
   */
  depositFee?: number
  /**
   * deposit fee due date
   * @format date
   */
  depositFeeDueDate?: string
  /**
   * due date for final amount
   * @format date
   */
  finalAmountDueDate?: string
  /** flags are used by the backend to advice the frontend what options are available to the booking at the time of fetching it */
  bookingControlFlags?: CargoBookingControlFlags
  /** flag identifying a group booking */
  isGroupBooking?: boolean
  namelist?: CargoNameListRow[]
  bookingContacts?: CargoBookingContactDetail[]
  cargoJourneys?: CargoJourneyRow[]
  extraBookingLines?: CargoExtraBookingRow[]
  ashoreBookingLines?: CargoAshoreBookingRow[]
}

export interface CargoBookingAshoreReferencedNameListRow {
  /**
   * references to a passenger row in the booking name list
   * @format int32
   * @max 99999
   */
  nameListRowNumber?: number
}

export interface CargoBookingAssignedCabinRow {
  cabinNumberCode?: string
  cabinNameList?: CargoBookingCabinNameListRow[]
}

export interface CargoBookingCabinNameListRow {
  /**
   * Field references to a passenger in booking name list
   * @format int32
   * @max 99999
   */
  bookingNameListRowNumber?: number
}

/** An array of Cargo Departures */
export interface CargoBookingCollection {
  bookings?: CargoBooking[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

export interface CargoBookingContactDetail {
  /**
   * contact's row number controlled by the backend
   * @format int32
   * @min 0
   * @max 99999
   */
  contactRowNumber?: number
  /**
   * contact type
   * @minLength 0
   * @maxLength 5
   */
  contactType?: string
  /**
   * contact info
   * @minLength 0
   * @maxLength 50
   */
  contactInfo?: string
  /** send info flag */
  sendInfo?: boolean
  /** auto sms flag */
  autoSms?: boolean
}

/** flags are used by the backend to advice the frontend what options are available to the booking at the time of fetching it */
export interface CargoBookingControlFlags {
  /** flags describes i the booking fits the cargo api definition of a valid booking */
  isValidCargoBooking?: boolean
  /** flag describes if the booking is currently being locked by another user (cannot be read or written) */
  isLocked?: boolean
  /** flag describes if canceling of the booking is possible through cargo api */
  isCancelAllowed?: boolean
  /** code describing the reason why cancel is not allowed */
  cancelNotAllowedReasonCode?: CargoBookingControlFlagsCancelNotAllowedReasonCode
  /** flag describes if updating of the booking is possible through cargo api */
  isUpdateAllowed?: boolean
  updateNotAllowedReasonCode?: CargoBookingControlFlagsUpdateNotAllowedReasonCode
}

export interface CargoBookingRowAssignedCabins {
  /** flag specifying of the cabins has been auto-assigned */
  autoAssigned?: boolean
  cabins?: CargoBookingAssignedCabinRow[]
}

export interface CargoBookingRowCabinProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  assignedCabins?: CargoBookingRowAssignedCabins
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowMealProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startDate?: string
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowMiscProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startDate?: string
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowPassengerProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowTimeSlotProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startDate?: string
  /** @format date-time */
  endDate?: string
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowVehicleProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity: number
  registrationNumbers?: CargoRegistrationNumber[]
  cargoDescriptions?: CargoDescription[]
  imdgs?: CargoImdg[]
  makeModels?: CargoBookingVehicleMakeModel[]
  /**
   * the weight of the load
   * @max 99999999.99
   * @exclusiveMax false
   */
  loadWeight?: number
  /**
   * the weight of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  tareWeight?: number
  /**
   * the length of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * the width of the vehicle
   * @max 999.99
   * @exclusiveMax false
   */
  width?: number
  /**
   * the height of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  height?: number
  /**
   * the amount of drivers
   * @format int32
   * @max 99999
   */
  driverCount?: number
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeConsignee?: string
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeShipper?: string
  /**
   * consignee information
   * @minLength 0
   * @maxLength 1000
   */
  consignee?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  consigneeCountryCode?: string
  /**
   * shipper information
   * @minLength 0
   * @maxLength 1000
   */
  shipper?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  shipperCountryCode?: string
  /**
   * consignment note
   * @minLength 0
   * @maxLength 10
   */
  consignmentNote?: string
  /**
   * temperature
   * @minLength 0
   * @maxLength 10
   */
  temperature?: string
  /**
   * notifier information
   * @minLength 0
   * @maxLength 1000
   */
  notifier?: string
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeNotifier?: string
  rowItems?: CargoBookingRowItem[]
}

/** Object representing Vehicle Make Model */
export interface CargoBookingVehicleMakeModel {
  /**
   * @minLength 0
   * @maxLength 50
   */
  vehicleMakeName?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  vehicleModelName?: string
  /**
   * @minLength 0
   * @maxLength 20
   */
  vehicleMakeModelCode?: string
}

/** Object representing Cargo description object */
export interface CargoDescription {
  /**
   * row number in the backend
   * @format int32
   */
  descriptionRowNumber?: number
  /**
   * the quantity of the items
   * @max 999999999999.99
   * @exclusiveMax false
   */
  quantity?: number
  /**
   * the type code
   * @minLength 0
   * @maxLength 10
   */
  cargoUnitTypeCode?: string
  /**
   * description
   * @minLength 0
   * @maxLength 1000
   */
  cargoDescription: string
  /**
   * gross weight
   * @max 9999999999.99
   * @exclusiveMax false
   */
  grossWeight?: number
  /**
   * net weight
   * @max 9999999999.99
   * @exclusiveMax false
   */
  netWeight?: number
  /**
   * customs code
   * @minLength 0
   * @maxLength 10
   */
  customsCode?: string
  /**
   * total monetary value of the goods
   * @min 0
   * @exclusiveMin false
   * @max 1000000000000
   * @exclusiveMax false
   */
  totalMonetaryValue?: number
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  originCountryExtCode?: string
  /** free circulation flag */
  inFreeCirculation?: boolean
  /** in transit flag */
  inTransit?: boolean
  /** from bonded warehouse flag */
  fromBondedWarehouse?: boolean
}

export interface CargoExtraBookingRow {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * @max 1000000000000
   * @exclusiveMax false
   */
  customerPrice?: number
  /**
   * @max 1000000000000
   * @exclusiveMax false
   */
  vat?: number
  /**
   * @max 1000000000000
   * @exclusiveMax false
   */
  displayPrice?: number
  hidePrice?: boolean
  isAutoAttached?: boolean
  /** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
  extraReferenceNumbers?: CargoExtraRowReferenceNumber[]
  /** @format date-time */
  startDate?: string
  rowNotes?: CargoExtraRowNote[]
  rowItems?: CargoBookingRowItem[]
  connectedExtraBookingRows?: CargoExtraConnectedBookingRow[]
}

export interface CargoExtraConnectedBookingRow {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * @max 1000000000000
   * @exclusiveMax false
   */
  customerPrice?: number
  /**
   * @max 1000000000000
   * @exclusiveMax false
   */
  vat?: number
  /**
   * @max 1000000000000
   * @exclusiveMax false
   */
  displayPrice?: number
  hidePrice?: boolean
  isAutoAttached?: boolean
  /** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
  extraReferenceNumbers?: CargoExtraRowReferenceNumber[]
  /** @format date-time */
  startDate?: string
  rowNotes?: CargoExtraRowNote[]
  rowItems?: CargoBookingRowItem[]
}

export interface CargoExtraRowNote {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * @minLength 0
   * @maxLength 10
   */
  noteType?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  noteData?: string
}

/** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
export interface CargoExtraRowReferenceNumber {
  /**
   * @minLength 0
   * @maxLength 10
   */
  referenceNumber?: string
}

/** Object representing Cargo IMDG object */
export interface CargoImdg {
  /**
   * IMDG class code
   * @minLength 0
   * @maxLength 10
   */
  imdgClassCode?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  imdgRowNumber?: number
  /**
   * UN number code
   * @minLength 0
   * @maxLength 10
   */
  unNumberCode?: string
  /** Object representing UN name. */
  unName?: CargoUnName
  /** Object representing the packaging options for class */
  emsAndPackagingGroup?: CargoImdgEmsAndPackagingGroup
  /**
   * weight
   * @max 99999999.99
   * @exclusiveMax false
   */
  weight?: number
  /**
   * net weight
   * @max 99999999.99
   * @exclusiveMax false
   */
  netWeight?: number
  /**
   * flash point
   * @format int32
   * @max 99999
   */
  flashPoint?: number
  /**
   * the note
   * @minLength 0
   * @maxLength 1000
   */
  note?: string
  /** marine pollutant flag */
  marinePollutant?: boolean
  /** limited quantity flag */
  limitedQuantity?: boolean
}

export interface CargoJourneyBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** checked in flag */
  checkedIn?: boolean
  /**
   * status code
   * @minLength 0
   * @maxLength 5
   */
  status?: string
  /** flag describing if the row was automatically attached */
  isAutoAttached?: boolean
  recordType?: CargoJourneyBookingRowRecordType
  /**
   * customer price
   * @max 1000000000000
   * @exclusiveMax false
   */
  customerPrice?: number
  /**
   * vat amount
   * @max 1000000000000
   * @exclusiveMax false
   */
  vat?: number
  /**
   * price to display
   * @max 1000000000000
   * @exclusiveMax false
   */
  displayPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  /**
   * price group
   * @minLength 0
   * @maxLength 10
   */
  priceGroup?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  availabilityGroupType?: CargoJourneyBookingRowAvailabilityGroupType
  vehicleProperties?: CargoBookingRowVehicleProps
  cabinProperties?: CargoBookingRowCabinProps
  passengerProperties?: CargoBookingRowPassengerProps
  miscProperties?: CargoBookingRowMiscProps
  timeslotProperties?: CargoBookingRowTimeSlotProps
  mealProperties?: CargoBookingRowMealProps
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  externalIds?: ExternalId[]
  connectedBookingRows?: CargoJourneyConnectedBookingRow[]
}

export interface CargoJourneyConnectedBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** checked in flag */
  checkedIn?: boolean
  /**
   * status code
   * @minLength 0
   * @maxLength 5
   */
  status?: string
  /** flag describing if the row was automatically attached */
  isAutoAttached?: boolean
  recordType?: CargoJourneyConnectedBookingRowRecordType
  /**
   * customer price
   * @max 1000000000000
   * @exclusiveMax false
   */
  customerPrice?: number
  /**
   * vat amount
   * @max 1000000000000
   * @exclusiveMax false
   */
  vat?: number
  /**
   * price to display
   * @max 1000000000000
   * @exclusiveMax false
   */
  displayPrice?: number
  /** hide price flag */
  hidePrice?: boolean
  /**
   * price group
   * @minLength 0
   * @maxLength 10
   */
  priceGroup?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  availabilityGroupType?: CargoJourneyConnectedBookingRowAvailabilityGroupType
  vehicleProperties?: CargoBookingRowVehicleProps
  cabinProperties?: CargoBookingRowCabinProps
  passengerProperties?: CargoBookingRowPassengerProps
  miscProperties?: CargoBookingRowMiscProps
  timeslotProperties?: CargoBookingRowTimeSlotProps
  mealProperties?: CargoBookingRowMealProps
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  externalIds?: ExternalId[]
}

export interface CargoJourneyControlFlags {
  /** flag specifies the journey row is updatable */
  isUpdateAllowed?: boolean
  updateNotAllowedReasonCode?: CargoJourneyControlFlagsUpdateNotAllowedReasonCode
}

export interface CargoJourneyRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  journeyRowNumber?: number
  /**
   * journey code in carres
   * @minLength 0
   * @maxLength 10
   */
  journeyCode?: string
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /**
   * the time of the departure
   * @format date-time
   */
  departureTime?: string
  /**
   * the time of the arrival
   * @format date-time
   */
  arrivalTime?: string
  /**
   * open journey type
   * @minLength 0
   * @maxLength 50
   */
  openJourneyType?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortExtCode?: string
  /**
   * translated port name used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortName?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortExtCode?: string
  /**
   * translated port name used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortName?: string
  /**
   * vessel code used in carres
   * @minLength 0
   * @maxLength 10
   */
  vesselCode?: string
  /**
   * the full name of the vessel
   * @minLength 0
   * @maxLength 50
   */
  vesselName?: string
  /**
   * journey price
   * @min 0
   * @exclusiveMin false
   * @max 1000000000000
   * @exclusiveMax false
   */
  journeyPrice?: number
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /**
   * promotion code used for the journey
   * @minLength 0
   * @maxLength 10
   */
  promotionCode?: string
  /** @format date-time */
  earliestCheckinTime?: string
  /** @format date-time */
  latestCheckinTime?: string
  journeyControlFlags?: CargoJourneyControlFlags
  journeyBookingRows?: CargoJourneyBookingRow[]
}

export interface CargoNameListRow {
  /**
   * reference number
   * @minLength 0
   * @maxLength 10
   */
  referenceNumber?: string
  /**
   * qr code in text format
   * @minLength 0
   * @maxLength 2000
   */
  qrCodeText?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * guest code
   * @minLength 0
   * @maxLength 10
   */
  guestCode?: string
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * middle name
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName?: string
  /**
   * The title code
   * @minLength 0
   * @maxLength 5
   */
  titleCode?: string
  /**
   * passenger's day of birth
   * @format date
   */
  dateOfBirth?: string
  /**
   * passenger's birth place
   * @minLength 0
   * @maxLength 50
   */
  birthPlace?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  nationalityExtCode?: string
  /**
   * gender code
   * @minLength 0
   * @maxLength 1
   */
  genderCode?: string
  /** disability flag */
  disability?: boolean
  /**
   * The email address
   * @minLength 0
   * @maxLength 100
   */
  email?: string
  /**
   * The mobile number
   * @minLength 0
   * @maxLength 50
   */
  mobile?: string
  /**
   * The passport number
   * @minLength 0
   * @maxLength 30
   */
  passportNumber?: string
  /**
   * passenger's passport expiry date
   * @format date
   */
  passportExpiryDate?: string
  /**
   * passenger's passport issue date
   * @format date
   */
  passportIssueDate?: string
  /**
   * travel document type code
   * @minLength 0
   * @maxLength 10
   */
  travelDocumentTypeCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  travelDocumentCountryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  travelDocumentStateCode?: string
  /**
   * passenger's visa number
   * @minLength 0
   * @maxLength 20
   */
  visaNumber?: string
  /**
   * disability note
   * @minLength 0
   * @maxLength 1000
   */
  disabilityNote?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** group leader flag */
  groupLeader?: boolean
  /**
   * The guest type external code
   * @minLength 0
   * @maxLength 50
   */
  guestTypeCode?: string
  /** assistance flag */
  assistance?: boolean
  /** assistance approved flag */
  assistanceApproved?: boolean
  /**
   * contact number in destination
   * @minLength 0
   * @maxLength 50
   */
  destinationContactNumber?: string
  /** email confirmation flag */
  mailConfirmation?: boolean
  /** sms confirmation flag */
  smsConfirmation?: boolean
}

/** Object representing Cargo registration number */
export interface CargoRegistrationNumber {
  primary?: boolean
  /**
   * Registration plate number
   * @minLength 0
   * @maxLength 50
   */
  registrationNumberCode: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  registrationStateCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  registrationCountryCode?: string
  /** This contains information about the driver. Only one of name and name list row number is populated.  */
  driverInfo?: DriverInfo
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @format int32
   * @max 99999
   */
  driversNameListRowNumber?: number
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @minLength 0
   * @maxLength 50
   */
  driverNote?: string
}

/** A Customs Code Object. */
export interface CargoCustomsCode {
  /**
   * customs code
   * @minLength 0
   * @maxLength 10
   */
  customsCode?: string
  /**
   * name of the customs code
   * @minLength 0
   * @maxLength 1000
   */
  customsCodeName?: string
}

/** An array of Customs Code objects */
export interface CargoCustomsCodeCollection {
  customsCodes?: CargoCustomsCode[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

/** A list of nationalities */
export interface CargoCountriesCollection {
  countries?: CargoCountry[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

/** Country object */
export interface CargoCountry {
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
  /**
   * Country name
   * @minLength 0
   * @maxLength 50
   */
  countryName?: string
  /**
   * phone code
   * @minLength 0
   * @maxLength 10
   */
  phoneCode?: string
}

/** Cargo unit type object */
export interface CargoCargoUnitType {
  /**
   * Cargo unit type code
   * @minLength 0
   * @maxLength 10
   */
  cargoUnitTypeCode?: string
  /**
   * Name of cargo unit type
   * @minLength 0
   * @maxLength 50
   */
  cargoUnitTypeName?: string
}

/** A list of cargo unit types. */
export interface CargoCargoUnitTypeCollection {
  cargoDescriptionTypes?: CargoCargoUnitType[]
  _links?: CollectionLinks
  _meta?: CollectionMeta
}

export interface BookingStatus {
  /**
   * Status of the booking. Note that booking status comes from a user-defined list and meanings may vary. See cancelled field for a Boolean status if the booking is cancelled or not.
   * @minLength 0
   * @maxLength 10
   */
  bookingStatusCode?: string
  /**
   * Name of the booking status
   * @minLength 0
   * @maxLength 50
   */
  bookingStatusName?: string
  /** Main booking status */
  mainBookingStatus?: BookingStatusMainBookingStatus
  /** Is user allowed to book with this status */
  isBookable?: boolean
}

export interface BookingStatusCollection {
  _links?: CollectionLinks
  _meta?: CollectionMeta
  bookingStatus?: BookingStatus[]
}

export enum Error400RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error401RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error403RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum ResourceIdentifierIdentifierType {
  ACCOUNTING_JOB_NUMBER = 'ACCOUNTING_JOB_NUMBER',
  ACCOUNTING_SYSTEM_CODE = 'ACCOUNTING_SYSTEM_CODE',
  ACTOR_CODE = 'ACTOR_CODE',
  AGENT_CODE = 'AGENT_CODE',
  APISYS_CODE = 'APISYS_CODE',
  APP_CODE = 'APP_CODE',
  APP_INSTANCE_CODE = 'APP_INSTANCE_CODE',
  APP_INSTANCE_LOCALE_CODE = 'APP_INSTANCE_LOCALE_CODE',
  AUTOMAT_CODE = 'AUTOMAT_CODE',
  BOOKING_CODE = 'BOOKING_CODE',
  BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP_CODE = 'BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP_CODE',
  BOOKING_ROW_ITEM_REFERENCE = 'BOOKING_ROW_ITEM_REFERENCE',
  BOOKING_VERSION_ID = 'BOOKING_VERSION_ID',
  BOOKING_VERSION_NUMBER = 'BOOKING_VERSION_NUMBER',
  BROCHURE_CODE = 'BROCHURE_CODE',
  CARGO_TYPE_CODE = 'CARGO_TYPE_CODE',
  CATEGORY_CODE = 'CATEGORY_CODE',
  CATEGORY_GROUP_CODE = 'CATEGORY_GROUP_CODE',
  CATEGORY_STRUCT_GROUP_CODE = 'CATEGORY_STRUCT_GROUP_CODE',
  CLUB_CODE = 'CLUB_CODE',
  CLUB_LEVEL = 'CLUB_LEVEL',
  CONSIGNEE_CODE = 'CONSIGNEE_CODE',
  COUNTRY_CODE = 'COUNTRY_CODE',
  COUNTRY_EXT_CODE = 'COUNTRY_EXT_CODE',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CUSTOMER_CODE = 'CUSTOMER_CODE',
  CUSTOMER_PRIVILEGE_GROUP_CODE = 'CUSTOMER_PRIVILEGE_GROUP_CODE',
  CUSTOMER_SUB_LOGIN_CODE = 'CUSTOMER_SUB_LOGIN_CODE',
  DEFAULT_ARRIVAL_PORT_CODE = 'DEFAULT_ARRIVAL_PORT_CODE',
  DEPARTURE_CODE = 'DEPARTURE_CODE',
  EMAIL_TEMPLATE_CODE = 'EMAIL_TEMPLATE_CODE',
  EINVOICE_TYPE_CODE = 'EINVOICE_TYPE_CODE',
  ETICKET_CODE = 'ETICKET_CODE',
  ETICKET_TYPE_CODE = 'ETICKET_TYPE_CODE',
  EXTERNAL_ACCESS_REFERENCE_NUMBER = 'EXTERNAL_ACCESS_REFERENCE_NUMBER',
  EXTERNAL_ACCESS_REFERENCE_TYPE = 'EXTERNAL_ACCESS_REFERENCE_TYPE',
  EXTERNAL_ACCESS_RESOURCE_CODE = 'EXTERNAL_ACCESS_RESOURCE_CODE',
  EXTERNAL_ACCESS_RESOURCE_GRANT_ACCESS_ID = 'EXTERNAL_ACCESS_RESOURCE_GRANT_ACCESS_ID',
  EXTERNAL_ACCESS_RESOURCE_TYPE = 'EXTERNAL_ACCESS_RESOURCE_TYPE',
  GUEST_TYPE_CODE = 'GUEST_TYPE_CODE',
  INVOICING_INTERVAL_CODE = 'INVOICING_INTERVAL_CODE',
  INVOICING_JOB_NUMBER = 'INVOICING_JOB_NUMBER',
  JOURNEY_CODE = 'JOURNEY_CODE',
  JOURNEY_GROUP_CODE = 'JOURNEY_GROUP_CODE',
  JOURNEY_NAME_LIST_REFERENCE = 'JOURNEY_NAME_LIST_REFERENCE',
  LANGUAGE_CODE = 'LANGUAGE_CODE',
  METHOD_OF_PAYMENT_CODE = 'METHOD_OF_PAYMENT_CODE',
  MOBILE_DEVICE_CODE = 'MOBILE_DEVICE_CODE',
  MOBILE_KEY_CODE = 'MOBILE_KEY_CODE',
  MOBILE_KEY_LOCK_TYPE = 'MOBILE_KEY_LOCK_TYPE',
  NAME_LIST_ROW_NUMBER = 'NAME_LIST_ROW_NUMBER',
  NATIONALITY_CODE = 'NATIONALITY_CODE',
  PORT_CODE = 'PORT_CODE',
  PORT_EXT_CODE = 'PORT_EXT_CODE',
  PRODUCT_CODE = 'PRODUCT_CODE',
  PAYMENT_REFERENCE = 'PAYMENT_REFERENCE',
  RECRUITMENT_METHOD_CODE = 'RECRUITMENT_METHOD_CODE',
  SHIPPER_CODE = 'SHIPPER_CODE',
  SMS_TEMPLATE_CODE = 'SMS_TEMPLATE_CODE',
  STATE_CODE = 'STATE_CODE',
  TAX_OFFICE_CODE = 'TAX_OFFICE_CODE',
  TERMS_AND_CONDITIONS_TEXT_GROUP_CODE = 'TERMS_AND_CONDITIONS_TEXT_GROUP_CODE',
  TERMS_OF_PAYMENT_CODE = 'TERMS_OF_PAYMENT_CODE',
  TERMINAL_CODE = 'TERMINAL_CODE',
  TITLE_CODE = 'TITLE_CODE',
  TRANSACTION_NUMBER = 'TRANSACTION_NUMBER',
  TRAVEL_DOCUMENT_TYPE_CODE = 'TRAVEL_DOCUMENT_TYPE_CODE',
  VEHICLE_MAKE_MODEL_CODE = 'VEHICLE_MAKE_MODEL_CODE',
  VOUCHER_CODE = 'VOUCHER_CODE',
  UNKNOWN = 'UNKNOWN'
}

export enum ResourceReferenceResourceType {
  ACCOUNTING_JOB = 'ACCOUNTING_JOB',
  ACCOUNTING_SYSTEM = 'ACCOUNTING_SYSTEM',
  ACTOR = 'ACTOR',
  AGENT = 'AGENT',
  APISYS = 'APISYS',
  APP = 'APP',
  APP_INSTANCE = 'APP_INSTANCE',
  APP_INSTANCE_LOCALE = 'APP_INSTANCE_LOCALE',
  AUTOMAT = 'AUTOMAT',
  BOOKING = 'BOOKING',
  BOOKING_ROW_ITEM = 'BOOKING_ROW_ITEM',
  BOOKING_NAME_LIST_ROW = 'BOOKING_NAME_LIST_ROW',
  BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP = 'BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP',
  BOOKING_VERSION = 'BOOKING_VERSION',
  BROCHURE_PRODUCT = 'BROCHURE_PRODUCT',
  CARGO_TYPE = 'CARGO_TYPE',
  CATEGORY_STRUCT = 'CATEGORY_STRUCT',
  CATEGORY_STRUCT_GROUP = 'CATEGORY_STRUCT_GROUP',
  CLUB = 'CLUB',
  COUNTRY = 'COUNTRY',
  CURRENCY = 'CURRENCY',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_PRIVILEGE_GROUP = 'CUSTOMER_PRIVILEGE_GROUP',
  CUSTOMER_SUB_LOGIN = 'CUSTOMER_SUB_LOGIN',
  DEPARTURE = 'DEPARTURE',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  EINVOICE = 'EINVOICE',
  ETICKET = 'ETICKET',
  EXTERNAL_ACCESS_REFERENCE = 'EXTERNAL_ACCESS_REFERENCE',
  EXTERNAL_ACCESS_RESOURCE = 'EXTERNAL_ACCESS_RESOURCE',
  EXTERNAL_ACCESS_RESOURCE_GRANT = 'EXTERNAL_ACCESS_RESOURCE_GRANT',
  GUEST_TYPE = 'GUEST_TYPE',
  INVOICING_INTERVAL = 'INVOICING_INTERVAL',
  INVOICING_JOB = 'INVOICING_JOB',
  JOURNEY = 'JOURNEY',
  JOURNEY_GROUP = 'JOURNEY_GROUP',
  JOURNEY_NAME_LIST_REFERENCE = 'JOURNEY_NAME_LIST_REFERENCE',
  LANGUAGE = 'LANGUAGE',
  METHOD_OF_PAYMENT = 'METHOD_OF_PAYMENT',
  MOBILE_DEVICE = 'MOBILE_DEVICE',
  MOBILE_KEY = 'MOBILE_KEY',
  MOBILE_KEY_LOCK_TYPE = 'MOBILE_KEY_LOCK_TYPE',
  PAYMENT = 'PAYMENT',
  PORT = 'PORT',
  RECRUITMENT_METHOD = 'RECRUITMENT_METHOD',
  SMS_TEMPLATE = 'SMS_TEMPLATE',
  STATE = 'STATE',
  TAX_OFFICE = 'TAX_OFFICE',
  TERMS_AND_CONDITIONS_TEXT_GROUP = 'TERMS_AND_CONDITIONS_TEXT_GROUP',
  TERMS_OF_PAYMENT = 'TERMS_OF_PAYMENT',
  TERMINAL = 'TERMINAL',
  TITLE = 'TITLE',
  TRAVEL_DOCUMENT = 'TRAVEL_DOCUMENT',
  VEHICLE_MAKE_MODEL = 'VEHICLE_MAKE_MODEL',
  VOUCHER = 'VOUCHER',
  UNKNOWN = 'UNKNOWN'
}

export enum Error404RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error406RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error409RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error429RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error422RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error422ErrorSubType {
  REFERENCED_RESOURCE_NOT_FOUND = 'REFERENCED_RESOURCE_NOT_FOUND',
  REFERENCED_RESOURCE_FORBIDDEN = 'REFERENCED_RESOURCE_FORBIDDEN',
  REFERENCED_RESOURCE_CONFLICT = 'REFERENCED_RESOURCE_CONFLICT',
  UNKNOWN = 'UNKNOWN'
}

export enum Error500RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum CargoSelfServiceApiConflictReasonConflictReasonType {
  CUSTOMER_SUB_LOGIN_CODE_NOT_UNIQUE = 'CUSTOMER_SUB_LOGIN_CODE_NOT_UNIQUE',
  EMAIL_ADDRESS_INVALID = 'EMAIL_ADDRESS_INVALID',
  EMAIL_ADDRESS_NOT_UNIQUE = 'EMAIL_ADDRESS_NOT_UNIQUE',
  PASSWORD_POLICY_VIOLATED = 'PASSWORD_POLICY_VIOLATED',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoSelfServiceApiError409RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum CargoExtVehicleSummaryVehicleType {
  Trailer = 'trailer',
  Trucker = 'trucker',
  DriverlessCar = 'driverlessCar',
  Unknown = 'unknown'
}

export enum NameListItemGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum CargoAvailabilityAttachedRowsCabinAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsCabinRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsMealAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsMealRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsMiscAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsMiscRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsPassengerAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsPassengerRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsVehicleAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsVehicleRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityAttachedRowsWrapperAttachedItemType {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityCabinAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityCabinRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityMealAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityMealRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityMiscAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityMiscRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityPassengerAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityPassengerRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsCabinAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsCabinRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsMealAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsMealRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsMiscAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsMiscRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsPassengerAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsPassengerRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsVehicleAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsVehicleRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityUpsellRowsWrapperUpsellItemType {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityVehicleAvailableStatusCode {
  SOLD_OUT = 'SOLD_OUT',
  AVAILABLE = 'AVAILABLE',
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAvailabilityVehicleRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

/** Token type. Possible values are - X-Carus-Auth - to be submitted as X-Carus-Auth header  */
export enum TokenResponseTokenType {
  XCarusAuth = 'X-Carus-Auth'
}

export enum CustomerPrivilegeSettingDataType {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN'
}

export enum ExternalIdTypeValidationForceToCase {
  NO_CHECK = 'NO_CHECK',
  LOWERCASE = 'LOWERCASE',
  UPPERCASE = 'UPPERCASE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoExtCategoryAvailabilityGroupType {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAshoreBookingRowAvailabilityGroupCode {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoAshoreConnectedBookingRowAvailabilityGroupCode {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

/** code describing the reason why cancel is not allowed */
export enum CargoBookingControlFlagsCancelNotAllowedReasonCode {
  GROUP_BOOKING = 'GROUP_BOOKING',
  OTHER_REASON = 'OTHER_REASON'
}

export enum CargoBookingControlFlagsUpdateNotAllowedReasonCode {
  MISSING_CATEGORY_TRANSLATION = 'MISSING_CATEGORY_TRANSLATION',
  MANUAL_PRICE_USED = 'MANUAL_PRICE_USED',
  ROW_ALREADY_CHECKED_IN = 'ROW_ALREADY_CHECKED_IN',
  JOURNEY_IS_IN_THE_PAST = 'JOURNEY_IS_IN_THE_PAST',
  BOOKING_IS_READONLY = 'BOOKING_IS_READONLY',
  CUSTOMER_NOT_ALLOWED_FOR_API = 'CUSTOMER_NOT_ALLOWED_FOR_API',
  PRODUCT_DOES_NOT_ALLOW_UPDATES = 'PRODUCT_DOES_NOT_ALLOW_UPDATES',
  GROUP_BOOKING = 'GROUP_BOOKING',
  INVALID_CARGO_BOOKING = 'INVALID_CARGO_BOOKING',
  LOCKED_BY_OTHER_USER = 'LOCKED_BY_OTHER_USER',
  OTHER_REASON = 'OTHER_REASON'
}

export enum CargoJourneyBookingRowRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoJourneyBookingRowAvailabilityGroupType {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoJourneyConnectedBookingRowRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoJourneyConnectedBookingRowAvailabilityGroupType {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum CargoJourneyControlFlagsUpdateNotAllowedReasonCode {
  MISSING_CATEGORY_TRANSLATION = 'MISSING_CATEGORY_TRANSLATION',
  MANUAL_PRICE_USED = 'MANUAL_PRICE_USED',
  ROW_ALREADY_CHECKED_IN = 'ROW_ALREADY_CHECKED_IN',
  JOURNEY_IS_IN_THE_PAST = 'JOURNEY_IS_IN_THE_PAST',
  BOOKING_IS_READONLY = 'BOOKING_IS_READONLY',
  CUSTOMER_NOT_ALLOWED_FOR_API = 'CUSTOMER_NOT_ALLOWED_FOR_API',
  PRODUCT_DOES_NOT_ALLOW_UPDATES = 'PRODUCT_DOES_NOT_ALLOW_UPDATES',
  GROUP_BOOKING = 'GROUP_BOOKING',
  INVALID_CARGO_BOOKING = 'INVALID_CARGO_BOOKING',
  LOCKED_BY_OTHER_USER = 'LOCKED_BY_OTHER_USER',
  OTHER_REASON = 'OTHER_REASON'
}

/** Main booking status */
export enum BookingStatusMainBookingStatus {
  OK = 'OK',
  CANCEL = 'CANCEL',
  UNKNOWN = 'UNKNOWN'
}

export type GetCargoUnitWithCodeData = CargoVehicleUnit

export type UpdateCargoUnitWithCodeData = ResourceLinks

export type DeleteCargoUnitWithCodeData = object

export type GetCargoBookingWithCodeData = CargoBooking

export type UpdateCargoBookingOnDepartureWithVersionCodeData = ResourceLinks

export type GetAllCargoUnitsToCustomerData = CargoVehicleUnitCollection

export type CreateCargoUnitData = ResourceLinks

export type SendLoginOtpViaEmailData = any

export type GetCargoCustomerSubLoginsData = CargoCustomerSubLoginsCollection

export type CreateCustomerSubLoginData = ResourceLinks

export type GetCargoBookingsForDepartureData = CargoBookingCollection

export type CreateCargoNewCargoBookingData = ResourceLinks

export type CopyCargoBookingToJourneyAndDepartureData = ResourceLinks

export type BookingSearchData = CargoExtSearchResultsCollection

export type PostCargoAvailabilityTimeAndPriceAvailabilityData =
  CargoAvailabilitySearchResult

export type RefreshTokenData = TokenResponse

export type GenerateCustomerSubLoginTokenData = TokenResponse

export type GenerateCustomerSubLoginTokenByOneTimePasswordData = TokenResponse

export type DeactivateCargoUnitWithCodeData = ResourceLinks

export type GetCargoCustomerData = CargoCustomer

export type UpdateCargoCustomerInformationData = ResourceLinks

export type GetCargoCustomerSubLoginData = CargoCustomerSubLogin

export type DeleteCustomerSubLoginData = any

export type UpdateCustomerSubLoginData = ResourceLinks

export type UpdateAllotmentsData = CargoCustomerAllotment

export type MoveCargoBookingToNewDepartureData = ResourceLinks

export type CancelCargoBookingData = CargoBookingCancelled

export type GetImdgClassesForVesselData = CargoImdgClassCollection

export type GetAllCargoPortsData = CargoExtPortsCollection

export type GetCargoMyLoginInformationData = CargoLoginInformation

export type GetCustomerPrivilegeGroupsData = CargoCustomerPrivilegeGroupsCollection

export type GetCargoAllJourneysData = CargoJourneysCollection

export type GetImdgUnNumbersForImdgClassData = CargoImdgUnNumberWithOptionsCollection

export type GetAllCargoGendersData = CargoGendersCollection

export type GetExternalIdTypesData = ExternalIdTypeCollection

export type GetAllCargoProductsData = CargoProductCollection

export type GetCargoAllExtCategoriesData = CargoExtCategoriesCollection

export type GetCargoApiAllDeparturesToCustomerData = CargoDepartureCollection

export type GetCargoApiDepartureToCustomerWithCodeData = CargoDeparture

export type GetCargoCustomsCodesData = CargoCustomsCodeCollection

export type GetAllCargoCountriesData = CargoCountriesCollection

export type GetAllCargoUnitTypesData = CargoCargoUnitTypeCollection

export type GetBookingStatusesData = BookingStatusCollection

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL:
        axiosConfig.baseURL ||
        'https://api-test-rec.carus.com/cargo-self-service/rectest/v1'
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title Cargo Self-Service API
 * @version 11.0.0
 * @license Carus License (https://www.carus.com)
 * @termsOfService https://www.carus.com
 * @baseUrl https://api-test-rec.carus.com/cargo-self-service/rectest/v1
 * @contact Carus <support@carus.com> (https://www.carus.com)
 *
 * Cargo Self-Service Booking API
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  cargoSelfService = {
    /**
     * No description
     *
     * @tags CargoSelfServiceFleetMgt
     * @name GetCargoUnitWithCode
     * @summary Get a specific customer's cargo unit
     * @request GET:/cargo-self-service/v1/units/{vehicleUnitNumber}
     * @secure
     * @response `200` `GetCargoUnitWithCodeData` Vehicle Unit
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoUnitWithCode: (
      vehicleUnitNumber: number,
      query?: {
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoUnitWithCodeData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/units/${vehicleUnitNumber}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceFleetMgt
     * @name UpdateCargoUnitWithCode
     * @summary Update a Cargo Unit
     * @request PUT:/cargo-self-service/v1/units/{vehicleUnitNumber}
     * @secure
     * @response `200` `UpdateCargoUnitWithCodeData` Link to updated vehicle unit
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    updateCargoUnitWithCode: (
      vehicleUnitNumber: number,
      data: CreateCargoVehicleUnitRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateCargoUnitWithCodeData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/units/${vehicleUnitNumber}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceFleetMgt
     * @name DeleteCargoUnitWithCode
     * @summary Delete cargo unit
     * @request DELETE:/cargo-self-service/v1/units/{vehicleUnitNumber}
     * @secure
     * @response `200` `DeleteCargoUnitWithCodeData` Vehicle unit was deleted successfully
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    deleteCargoUnitWithCode: (
      vehicleUnitNumber: number,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteCargoUnitWithCodeData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/units/${vehicleUnitNumber}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceBookings
     * @name GetCargoBookingWithCode
     * @summary Fetch a booking with booking code
     * @request GET:/cargo-self-service/v1/bookings/{bookingCode}
     * @secure
     * @response `200` `GetCargoBookingWithCodeData` Booking
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoBookingWithCode: (
      bookingCode: string,
      query: {
        /**
         * Allowed to recall a cancelled booking if set to true
         * @default false
         */
        recallCancelled: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoBookingWithCodeData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/bookings/${bookingCode}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceBookings
     * @name UpdateCargoBookingOnDepartureWithVersionCode
     * @summary Update booking data
     * @request PUT:/cargo-self-service/v1/bookings/{bookingCode}
     * @secure
     * @response `200` `UpdateCargoBookingOnDepartureWithVersionCodeData` Link to updated booking
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `422` `Error422` Error given if external id type not found, or use of external id type not permitted on category struct booking row.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    updateCargoBookingOnDepartureWithVersionCode: (
      bookingCode: string,
      data: UpdateCargoBookingRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateCargoBookingOnDepartureWithVersionCodeData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error422 | Error429
      >({
        path: `/cargo-self-service/v1/bookings/${bookingCode}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceFleetMgt
     * @name GetAllCargoUnitsToCustomer
     * @summary Get list of all customer's cargo units
     * @request GET:/cargo-self-service/v1/units
     * @secure
     * @response `200` `GetAllCargoUnitsToCustomerData` List of current customers vehicle units
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getAllCargoUnitsToCustomer: (
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetAllCargoUnitsToCustomerData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/units`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceFleetMgt
     * @name CreateCargoUnit
     * @summary Create new cargo unit
     * @request POST:/cargo-self-service/v1/units
     * @secure
     * @response `201` `CreateCargoUnitData` Link to new vehicle unit
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    createCargoUnit: (
      data: CreateCargoVehicleUnitRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateCargoUnitData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/units`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserLogin
     * @name SendLoginOtpViaEmail
     * @summary Send a one-time-password that can be used for logging in.
     * @request POST:/cargo-self-service/v1/send-login-otp-via-email
     * @secure
     * @response `200` `SendLoginOtpViaEmailData` HTTP Ok - Successful response
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `422` `Error422` Referenced resource not found, or in wrong state. This can e.g. be if more than one sub-login is found, or an e-mail template is missing.
     * @response `500` `Error500` Unexpected error
     */
    sendLoginOtpViaEmail: (data: SendLoginOtpViaEmail, params: RequestParams = {}) =>
      this.request<
        SendLoginOtpViaEmailData,
        Error400 | Error401 | Error422 | Error500
      >({
        path: `/cargo-self-service/v1/send-login-otp-via-email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name GetCargoCustomerSubLogins
     * @summary Get cargo customer sub-logins
     * @request GET:/cargo-self-service/v1/me/customer/customer-sub-logins
     * @secure
     * @response `200` `GetCargoCustomerSubLoginsData` List of cargo customer sub-logins.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoCustomerSubLogins: (
      query?: {
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoCustomerSubLoginsData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/me/customer/customer-sub-logins`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name CreateCustomerSubLogin
     * @summary Creates a new customer sub-login
     * @request POST:/cargo-self-service/v1/me/customer/customer-sub-logins
     * @secure
     * @response `201` `CreateCustomerSubLoginData` Successfully created new customer sub-login.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Error given if not permitted to perform operation.
     * @response `404` `Error404` Object not found
     * @response `409` `CargoSelfServiceApiError409` Error given if customer sub-login code is not unique, password policy is violated, e-mail address is not unique or e-mail address is invalid. Note that if the e-mail contains white space, or no @-character in the middle, you will get a 400-error instead. Getting this 409-error, for invalid e-mail address, happens when validation fails at a deeper level that may even be environment specific - also valid e-mail addresses may be classified as invalid if they are too exotic in some way.
     * @response `422` `Error422` Error given if privilege group not found.
     */
    createCustomerSubLogin: (
      data: CreateCustomerSubLoginRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateCustomerSubLoginData,
        | Error400
        | Error401
        | Error403
        | Error404
        | CargoSelfServiceApiError409
        | Error422
      >({
        path: `/cargo-self-service/v1/me/customer/customer-sub-logins`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceBookings
     * @name GetCargoBookingsForDeparture
     * @summary Get collection of customer's bookings on a specific departure and journey
     * @request GET:/cargo-self-service/v1/departures/{departureCode}/{journeyCode}/bookings
     * @secure
     * @response `200` `GetCargoBookingsForDepartureData` List of bookings for current customer on this departure
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoBookingsForDeparture: (
      departureCode: string,
      journeyCode: string,
      query: {
        /**
         * Boolean value for including cancelled bookings.
         * @default false
         */
        includeCancelled: boolean
        /**
         * Include OK bookings (i.e. not cancelled)
         * @default true
         */
        includeOk: boolean
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoBookingsForDepartureData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/departures/${departureCode}/${journeyCode}/bookings`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceBookings
     * @name CreateCargoNewCargoBooking
     * @summary Create new booking to a departure
     * @request POST:/cargo-self-service/v1/departures/{departureCode}/{journeyCode}/bookings
     * @secure
     * @response `201` `CreateCargoNewCargoBookingData` Reference to created booking
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `422` `Error422` Error given if external id type not found, or use of external id type not permitted on category struct booking row.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    createCargoNewCargoBooking: (
      departureCode: string,
      journeyCode: string,
      data: CreateCargoBookingRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateCargoNewCargoBookingData,
        | Error400
        | Error401
        | Error403
        | Error404
        | Error406
        | Error409
        | Error422
        | Error429
      >({
        path: `/cargo-self-service/v1/departures/${departureCode}/${journeyCode}/bookings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceBookings
     * @name CopyCargoBookingToJourneyAndDeparture
     * @summary Copy booking to another departure
     * @request POST:/cargo-self-service/v1/bookings/{bookingCode}:copy
     * @secure
     * @response `201` `CopyCargoBookingToJourneyAndDepartureData` Link to new booking
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    copyCargoBookingToJourneyAndDeparture: (
      bookingCode: string,
      copy: string,
      query: {
        /** Departure code for the new booking */
        toDepartureCode: string
        /** Journey code for the new booking */
        toJourneyCode: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        CopyCargoBookingToJourneyAndDepartureData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/bookings/${bookingCode}${copy}`,
        method: 'POST',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceBookings
     * @name BookingSearch
     * @summary Search bookings
     * @request POST:/cargo-self-service/v1/bookings-search/{departureDateFromDate}/{departureDateFromHours}/{departureDateFromMins}/{departureDateToDate}/{departureDateToHours}/{departureDateToMins}
     * @secure
     * @response `200` `BookingSearchData` Summary data on bookings found
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    bookingSearch: (
      departureDateFromDate: string,
      departureDateFromHours: number,
      departureDateFromMins: number,
      departureDateToDate: string,
      departureDateToHours: number,
      departureDateToMins: number,
      data: SearchBookingsCriteria,
      query?: {
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        BookingSearchData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/bookings-search/${departureDateFromDate}/${departureDateFromHours}/${departureDateFromMins}/${departureDateToDate}/${departureDateToHours}/${departureDateToMins}`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceAvailability
     * @name PostCargoAvailabilityTimeAndPriceAvailability
     * @summary New availability search on given departure and journey
     * @request POST:/cargo-self-service/v1/availability-search/departures/{departureCode}/{journeyCode}
     * @secure
     * @response `200` `PostCargoAvailabilityTimeAndPriceAvailabilityData` List of available inventory
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    postCargoAvailabilityTimeAndPriceAvailability: (
      departureCode: string,
      journeyCode: string,
      data: CreateCargoAvailabilityRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PostCargoAvailabilityTimeAndPriceAvailabilityData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/availability-search/departures/${departureCode}/${journeyCode}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name RefreshToken
     * @summary Refreshes the token (if still valid) returns a Carus Auth Token if valid
     * @request POST:/cargo-self-service/v1/auth/tokens/refresh
     * @secure
     * @response `200` `RefreshTokenData` Refresh successful
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Error given if refresh token code is invalid, or refresh token is not of customer sub-login type.
     * @response `422` `Error422` Error given if customer sub-login, of the auth token, is no longer available.
     * @response `500` `Error500` Unexpected error
     */
    refreshToken: (data: TokenRefreshRequest, params: RequestParams = {}) =>
      this.request<
        RefreshTokenData,
        Error400 | Error401 | Error403 | Error422 | Error500
      >({
        path: `/cargo-self-service/v1/auth/tokens/refresh`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name GenerateCustomerSubLoginToken
     * @summary Validates the login and returns a Carus Auth Token if valid
     * @request POST:/cargo-self-service/v1/auth/tokens/customer-sub-logins
     * @secure
     * @response `200` `GenerateCustomerSubLoginTokenData` Authentication successful
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `404` `Error404` Object not found
     * @response `500` `Error500` Unexpected error
     */
    generateCustomerSubLoginToken: (
      data: TokenCreationRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        GenerateCustomerSubLoginTokenData,
        Error400 | Error401 | Error404 | Error500
      >({
        path: `/cargo-self-service/v1/auth/tokens/customer-sub-logins`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserLogin
     * @name GenerateCustomerSubLoginTokenByOneTimePassword
     * @summary Validates the one-time password and returns a Carus Auth Token if valid.
     * @request POST:/cargo-self-service/v1/auth/tokens/customer-sub-logins/one-time-password
     * @secure
     * @response `200` `GenerateCustomerSubLoginTokenByOneTimePasswordData` Authentication successful
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `404` `Error422` One-time password not found, or expired.
     * @response `409` `Error409` Error given if one or more of the one-time password details prevents login. This can be e.g. customer code no longer matching for an existing sub-login.
     * @response `500` `Error500` Unexpected error
     */
    generateCustomerSubLoginTokenByOneTimePassword: (
      data: CustomerSubLoginOneTimePassword,
      params: RequestParams = {}
    ) =>
      this.request<
        GenerateCustomerSubLoginTokenByOneTimePasswordData,
        Error400 | Error401 | Error422 | Error409 | Error500
      >({
        path: `/cargo-self-service/v1/auth/tokens/customer-sub-logins/one-time-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceFleetMgt
     * @name DeactivateCargoUnitWithCode
     * @summary Deactivate cargo unit
     * @request PATCH:/cargo-self-service/v1/units/{vehicleUnitNumber}:deactivate
     * @secure
     * @response `200` `DeactivateCargoUnitWithCodeData` Link to updated vehicle unit
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    deactivateCargoUnitWithCode: (
      vehicleUnitNumber: number,
      deactivate: string,
      params: RequestParams = {}
    ) =>
      this.request<
        DeactivateCargoUnitWithCodeData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/units/${vehicleUnitNumber}${deactivate}`,
        method: 'PATCH',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceCustomerInformation
     * @name GetCargoCustomer
     * @summary Get cargo customer details
     * @request GET:/cargo-self-service/v1/me/customer
     * @secure
     * @response `200` `GetCargoCustomerData` Details on current customer
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoCustomer: (
      query?: {
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoCustomerData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/me/customer`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceCustomerInformation
     * @name UpdateCargoCustomerInformation
     * @summary Update cargo customer information
     * @request PATCH:/cargo-self-service/v1/me/customer
     * @secure
     * @response `200` `UpdateCargoCustomerInformationData` Link to updated customer
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    updateCargoCustomerInformation: (
      data: UpdateCargoCustomerRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateCargoCustomerInformationData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/me/customer`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name GetCargoCustomerSubLogin
     * @summary Get specified cargo customer sub-login
     * @request GET:/cargo-self-service/v1/me/customer/customer-sub-logins/{customer-sub-login-code}
     * @secure
     * @response `200` `GetCargoCustomerSubLoginData` Cargo customer sub-login.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoCustomerSubLogin: (
      customerSubLoginCode: string,
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoCustomerSubLoginData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/me/customer/customer-sub-logins/${customerSubLoginCode}`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name DeleteCustomerSubLogin
     * @summary Delete customer sub-login
     * @request DELETE:/cargo-self-service/v1/me/customer/customer-sub-logins/{customer-sub-login-code}
     * @secure
     * @response `204` `DeleteCustomerSubLoginData` Customer sub-login was deleted successfully.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Error given if not permitted to perform operation. Note that a customer sub-login is not permitted to self-delete.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     */
    deleteCustomerSubLogin: (
      customerSubLoginCode: string,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteCustomerSubLoginData,
        Error400 | Error401 | Error403 | Error404 | Error406
      >({
        path: `/cargo-self-service/v1/me/customer/customer-sub-logins/${customerSubLoginCode}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name UpdateCustomerSubLogin
     * @summary Update a customer sub-login
     * @request PATCH:/cargo-self-service/v1/me/customer/customer-sub-logins/{customer-sub-login-code}
     * @secure
     * @response `200` `UpdateCustomerSubLoginData` Successfully updated customer sub-login.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Error given if not permitted to perform operation.
     * @response `404` `Error404` Error given if customer sub-login is not found.
     * @response `409` `CargoSelfServiceApiError409` Error given if password policy is violated, e-mail address is not unique or e-mail address is invalid. Note that if the e-mail address contains white space, or no @-character in the middle, you will get a 400-error instead. Getting this 409-error, for invalid e-mail address, happens when validation fails at a deeper level that may even be environment specific - also valid e-mail addresses may be classified as invalid if they are too exotic in some way.
     * @response `422` `Error422` Error given if privilege group not found.
     */
    updateCustomerSubLogin: (
      customerSubLoginCode: string,
      data: UpdateCustomerSubLoginRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateCustomerSubLoginData,
        | Error400
        | Error401
        | Error403
        | Error404
        | CargoSelfServiceApiError409
        | Error422
      >({
        path: `/cargo-self-service/v1/me/customer/customer-sub-logins/${customerSubLoginCode}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceDepartures
     * @name UpdateAllotments
     * @summary Update allotments for departure
     * @request PATCH:/cargo-self-service/v1/departures/{departureCode}/{journeyCode}/allotments
     * @secure
     * @response `200` `UpdateAllotmentsData` Information about capacity, booked and available allotments for departure, after the update.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Error given if customer sub-login is not authorized to perform action.
     * @response `404` `Error404` Error given if departure code or journey code is not found.
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error404` Error given if allotment is adjusted too high or too low. You typically can't increase allotment and you may not reduce beyond what has been used.
     * @response `422` `Error404` Error given if ext category code, or capacity group code, is not found on departure-journey combination.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    updateAllotments: (
      departureCode: string,
      journeyCode: string,
      data: UpdateCargoAllotmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateAllotmentsData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error429
      >({
        path: `/cargo-self-service/v1/departures/${departureCode}/${journeyCode}/allotments`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceBookings
     * @name MoveCargoBookingToNewDeparture
     * @summary Move a booking to a new departure
     * @request PATCH:/cargo-self-service/v1/bookings/{bookingCode}:move
     * @secure
     * @response `200` `MoveCargoBookingToNewDepartureData` Link to updated booking
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    moveCargoBookingToNewDeparture: (
      bookingCode: string,
      move: string,
      query: {
        /** departure code where booking is being moved to */
        toDepartureCode: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        MoveCargoBookingToNewDepartureData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/bookings/${bookingCode}${move}`,
        method: 'PATCH',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceBookings
     * @name CancelCargoBooking
     * @summary Cancel booking
     * @request PATCH:/cargo-self-service/v1/bookings/{bookingCode}:cancel
     * @secure
     * @response `200` `CancelCargoBookingData` Info on cancelled booking
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    cancelCargoBooking: (
      bookingCode: string,
      cancel: string,
      params: RequestParams = {}
    ) =>
      this.request<
        CancelCargoBookingData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/bookings/${bookingCode}${cancel}`,
        method: 'PATCH',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetImdgClassesForVessel
     * @summary Get list of all IMDG Classes
     * @request GET:/cargo-self-service/v1/vessels/{vesselCode}/imdg-classes
     * @secure
     * @response `200` `GetImdgClassesForVesselData` List of possible IMDG Classes for vessel
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getImdgClassesForVessel: (
      vesselCode: string,
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetImdgClassesForVesselData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/vessels/${vesselCode}/imdg-classes`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetAllCargoPorts
     * @summary Get all ports defined in the system
     * @request GET:/cargo-self-service/v1/ports
     * @secure
     * @response `200` `GetAllCargoPortsData` List of ports
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getAllCargoPorts: (
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetAllCargoPortsData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/ports`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name GetCargoMyLoginInformation
     * @summary Get user's cargo login information
     * @request GET:/cargo-self-service/v1/me
     * @secure
     * @response `200` `GetCargoMyLoginInformationData` Information about the customer sub-login that the API user is authenticated as.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Call will return an error if not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoMyLoginInformation: (params: RequestParams = {}) =>
      this.request<
        GetCargoMyLoginInformationData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error429
      >({
        path: `/cargo-self-service/v1/me`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceUserMgt
     * @name GetCustomerPrivilegeGroups
     * @summary Get customer privilege groups.
     * @request GET:/cargo-self-service/v1/me/customer/customer-privilege-groups
     * @secure
     * @response `200` `GetCustomerPrivilegeGroupsData` List of customer privilege groups.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Error given if not permitted to perform operation.
     * @response `404` `Error404` Error given if customer is no longer available.
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCustomerPrivilegeGroups: (
      query?: {
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCustomerPrivilegeGroupsData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error429
      >({
        path: `/cargo-self-service/v1/me/customer/customer-privilege-groups`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetCargoAllJourneys
     * @summary Get collection of all journeys defined in the system
     * @request GET:/cargo-self-service/v1/journeys
     * @secure
     * @response `200` `GetCargoAllJourneysData` List of possible journey definitions
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoAllJourneys: (
      query: {
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         * @min 1
         * @default 10
         */
        pageSize: number
        /**
         * Starting point for this page in a collection
         * @format int32
         * @min 1
         * @default 1
         */
        pageNum: number
        /** optional departure port search filter */
        departurePortExtCode?: string
        /** optional arrival port search filter */
        arrivalPortExtCode?: string
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoAllJourneysData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/journeys`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetImdgUnNumbersForImdgClass
     * @summary Get list of all IMDG UN numbers for specific class
     * @request GET:/cargo-self-service/v1/imdg-classes/{classCode}/un-numbers
     * @secure
     * @response `200` `GetImdgUnNumbersForImdgClassData` List of possible UN Numbers for IMDG class
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getImdgUnNumbersForImdgClass: (
      classCode: string,
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetImdgUnNumbersForImdgClassData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/imdg-classes/${classCode}/un-numbers`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetAllCargoGenders
     * @summary Get all genders defined in the system
     * @request GET:/cargo-self-service/v1/genders
     * @secure
     * @response `200` `GetAllCargoGendersData` List of genders
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getAllCargoGenders: (params: RequestParams = {}) =>
      this.request<
        GetAllCargoGendersData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/genders`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetExternalIdTypes
     * @summary Get a list of what type of external ids are available
     * @request GET:/cargo-self-service/v1/external-id-types
     * @secure
     * @response `200` `GetExternalIdTypesData` List of external id types
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getExternalIdTypes: (
      query: {
        /**
         * Boolean value for including inactive rows from the database.
         * @default false
         */
        includeInactive: boolean
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         * @min 1
         * @default 10
         */
        pageSize: number
        /**
         * Starting point for this page in a collection
         * @format int32
         * @min 1
         * @default 1
         */
        pageNum: number
      },
      params: RequestParams = {}
    ) =>
      this.request<GetExternalIdTypesData, Error400 | Error401 | Error429>({
        path: `/cargo-self-service/v1/external-id-types`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetAllCargoProducts
     * @summary Get all products available for the apisys
     * @request GET:/cargo-self-service/v1/ext-products
     * @secure
     * @response `200` `GetAllCargoProductsData` List of products
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getAllCargoProducts: (
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetAllCargoProductsData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/ext-products`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetCargoAllExtCategories
     * @summary Get list of all external categories configured to the apisys
     * @request GET:/cargo-self-service/v1/ext-categories
     * @secure
     * @response `200` `GetCargoAllExtCategoriesData` List of categories
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoAllExtCategories: (
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoAllExtCategoriesData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/ext-categories`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceDepartures
     * @name GetCargoApiAllDeparturesToCustomer
     * @summary Get list of all departures with customer's allotment and booking information
     * @request GET:/cargo-self-service/v1/departures
     * @secure
     * @response `200` `GetCargoApiAllDeparturesToCustomerData` List of departures
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoApiAllDeparturesToCustomer: (
      query: {
        /**
         * Limit the search to departures after (or equal to) this date (departureDateFromDate together with departureDateFromHours and departureDateFromMins provide the date-time to search from)
         * @format date
         */
        departureDateFromDate: string
        /**
         * Limit the search to departures after (or equal to) this hour of the day on the date specified (departureDateFromDate together with departureDateFromHours and departureDateFromMins provide the date-time to search from)
         * @format int32
         * @min 0
         * @max 23
         */
        departureDateFromHours: number
        /**
         * Limit the search to departures after (or equal to) this many minutes past the hour of the day on the date specified (departureDateFromDate together with departureDateFromHours and departureDateFromMins provide the date-time to search from)
         * @format int32
         * @min 0
         * @max 59
         */
        departureDateFromMins: number
        /**
         * Limit the search to departures before (or equal to) this date (departureDateToDate together with departureDateToHours and departureDateToMins provide the date-time to search to)
         * @format date
         */
        departureDateToDate: string
        /**
         * Limit the search to departures before (or equal to) this hour of the day on the date specified (departureDateToDate together with departureDateToHours and departureDateToMins provide the date-time to search to)
         * @format int32
         * @min 0
         * @max 23
         */
        departureDateToHours: number
        /**
         * Limit the search to departures before (or equal to) this many minutes past the hour of the day on the date specified (departureDateToDate together with departureDateToHours and departureDateToMins provide the date-time to search to)
         * @format int32
         * @min 0
         * @max 59
         */
        departureDateToMins: number
        /** optional flag to list all departures */
        showAllDepartures?: boolean
        /** optional parameter to limit the departures from selected port only */
        departurePortExtCode?: string
        /** optional parameter to limit the departures from selected port only */
        arrivalPortExtCode?: string
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoApiAllDeparturesToCustomerData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/departures`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceDepartures
     * @name GetCargoApiDepartureToCustomerWithCode
     * @summary Get a Departure details for a customer
     * @request GET:/cargo-self-service/v1/departures/{departureCode}/{journeyCode}
     * @secure
     * @response `200` `GetCargoApiDepartureToCustomerWithCodeData` List of Bookings on departure
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoApiDepartureToCustomerWithCode: (
      departureCode: string,
      journeyCode: string,
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoApiDepartureToCustomerWithCodeData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/departures/${departureCode}/${journeyCode}`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetCargoCustomsCodes
     * @summary Get customs codes
     * @request GET:/cargo-self-service/v1/customs-codes
     * @secure
     * @response `200` `GetCargoCustomsCodesData` List of customs codes
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoCustomsCodes: (
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoCustomsCodesData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/customs-codes`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetAllCargoCountries
     * @summary Get collection of all countries defined in the system
     * @request GET:/cargo-self-service/v1/countries
     * @secure
     * @response `200` `GetAllCargoCountriesData` List of countries
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getAllCargoCountries: (
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetAllCargoCountriesData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/countries`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetAllCargoUnitTypes
     * @summary Get list of all cargo unit types
     * @request GET:/cargo-self-service/v1/cargo-unit-types
     * @secure
     * @response `200` `GetAllCargoUnitTypesData` List of cargo unit types
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getAllCargoUnitTypes: (
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetAllCargoUnitTypesData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/cargo-unit-types`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceLists
     * @name GetBookingStatuses
     * @summary Get booking statuses defined in the system
     * @request GET:/cargo-self-service/v1/booking-status
     * @secure
     * @response `200` `GetBookingStatusesData` List of booking status
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getBookingStatuses: (
      query?: {
        /** Comma-separated list of fields used for sorting (add `-` to the end of field name to change sorting direction) */
        sort?: string[]
        /** Comma-separated list of fields which should be returned in response. */
        fields?: string
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetBookingStatusesData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/booking-status`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      })
  }
}
