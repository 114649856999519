import { useParams } from 'react-router-dom'
import { DefaultValues, FormProvider, useForm } from 'react-hook-form'
import {
  Container,
  Center,
  Flex,
  Box,
  Stack,
  Text,
  useToast,
  useBoolean,
  Alert,
  AlertIcon,
  Icon
} from '@chakra-ui/react'
import View from '../../components/View/View'
import BaseSubmitButton from '../../components/Form/BaseSubmitButton'
import FormInput from '../../components/FormControlled/FormInput'
import FormSelect from '../../components/FormControlled/FormSelect'
import { LocalShippingIcon, FaceIcon, ErrorIcon } from '../../icons'
import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import driverUpdateStore from '../../stores/DriverUpdateStore'
import BookingShortInfo from './BookingShortInfo'
import reservationStore from '../../stores/ReservationStore'
import { nameListToDrivers, toNumber } from '../../utils/helpers'
import { cargoTypes } from '../../utils/constants'
import { DriverCargoBooking } from '../../api/driverUpdateAPI'
import Portal from '../../helpers/Portal'
import Languages from '../../components/Languages'
import DriversListUpdate from './DriversListUpdate'
import { CargoNameListRow } from '../../api/cargoSelfServiceAPI'
import {
  decipherBookingRegNumbers,
  generateBookingUpdateBody,
  isBookingUpdatable
} from '../../utils/bookingHelpers'
import { toJS } from 'mobx'
import BaseFormLabel from '../../components/Form/BaseFormLabel'
import FormCheckbox from '../../components/FormControlled/FormCheckbox'
import { setRegNumberAs, vehicleRegNumberRule } from '../../utils/validationRules'
import { useTranslation } from 'react-i18next'

export type CargoForm = {
  vehicleRN: string
  vehicleRegistrationNumberCountryCode: string
  trailerRN: string
  trailerRegistrationNumberCountryCode: string
  loadWeight: number
  cargoType: string
  consignee: string
  drivers: CargoNameListRow[]
  dangerousGoods: boolean
  allDataIsCorrect: boolean
}

const mapBookingToFields = (
  booking: DriverCargoBooking
): DefaultValues<CargoForm> => {
  const { trailer, vehicle } = decipherBookingRegNumbers(
    booking?.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.vehicleProperties
      ?.registrationNumbers
  )
  return {
    drivers: nameListToDrivers(booking.namelist),
    vehicleRN: vehicle?.registrationNumberCode,
    vehicleRegistrationNumberCountryCode: vehicle?.registrationCountryCode,
    trailerRN: trailer?.registrationNumberCode,
    trailerRegistrationNumberCountryCode: trailer?.registrationCountryCode,
    loadWeight:
      booking?.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.vehicleProperties
        ?.loadWeight,
    cargoType:
      booking?.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.vehicleProperties
        ?.cargoDescriptions?.[0]?.cargoDescription,
    consignee:
      booking?.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.vehicleProperties
        ?.consignee
  }
}

const DriverUpdateContainer = observer(() => {
  const toast = useToast()
  const { token: tokenRaw } = useParams<{ token: string }>()
  const token = tokenRaw!
  const { t, i18n } = useTranslation()

  const [pending, setPending] = useBoolean()
  const [fetchingBooking, setFetchingBooking] = useBoolean(true)

  const [bookingFound, setBookingFound] = useBoolean(false)

  const { booking } = driverUpdateStore

  const { containsDangerousCargo, isUpdatable, isOutOfRange } = isBookingUpdatable({
    booking
  })

  const { control, handleSubmit, reset, getValues, ...formRest } =
    useForm<CargoForm>({
      defaultValues: {
        vehicleRN: '',
        trailerRN: '',
        loadWeight: undefined,
        cargoType: undefined,
        drivers: [],
        dangerousGoods: false,
        allDataIsCorrect: false
      },
      mode: 'all'
    })

  const resetValues = useCallback(
    (data: DriverCargoBooking) => reset(mapBookingToFields(data)),
    [reset]
  )

  const fetchBooking = useCallback(() => {
    setFetchingBooking.on()
    driverUpdateStore.getDriverUpdateInformation(token).then(async ([data, err]) => {
      setFetchingBooking.off()
      if (err !== null) {
        setBookingFound.off()
        return toast({
          title: t("can't_find_booking"),
          description: err,
          status: 'error',
          duration: 2000,
          isClosable: true
        })
      }
      setBookingFound.on()
      if (!reservationStore.nationalities.length) {
        await reservationStore.fetchNationalities()
      }
      resetValues(data)
    })
  }, [resetValues, token, toast]) // eslint-disable-line

  useEffect(fetchBooking, [fetchBooking])

  const onSubmit = handleSubmit(async (data) => {
    const isValid = await formRest.trigger()

    if (!isValid || !booking) {
      return
    }

    setPending.on()

    const body = generateBookingUpdateBody({
      booking: toJS(booking),
      cargoForm: data
    })

    const [response, error] =
      await driverUpdateStore.updateBookingInformationByToken({
        token,
        body
      })

    setPending.off()

    if (!error && response) {
      toast({
        title: t('booking_updated'),
        description: t('we_updated_your_booking_for_you'),
        status: 'success',
        isClosable: true
      })
      fetchBooking()
      return
    }

    toast({
      title: t('booking_was_not_updated'),
      description: error,
      status: 'error',
      isClosable: true
    })
  })

  useEffect(() => {
    return () => {
      i18n.changeLanguage('en')
    }
  }, [])

  const areFieldsDisabled =
    !bookingFound || !isUpdatable || pending || fetchingBooking

  return (
    <View title={t('driverUpdate')}>
      <FormProvider
        control={control}
        handleSubmit={handleSubmit}
        reset={reset}
        getValues={getValues}
        {...formRest}
      >
        <Portal elementId="header-bottom-id">
          <Languages />
        </Portal>
        <BookingShortInfo
          loading={driverUpdateStore.isBookingLoading}
          booking={booking}
        />
        <Container maxW="container.md" p={0}>
          <Box p={5} pb={10}>
            <Stack spacing={10}>
              <Stack spacing={5}>
                <Alert status="info">
                  <AlertIcon />
                  {t('use_only_latin_letters')}
                </Alert>
                {!fetchingBooking && !isUpdatable && (
                  <Alert status="error">
                    <AlertIcon />
                    {isOutOfRange ? t('cargo_is_out_of_range') + '. ' : ''}
                    {containsDangerousCargo
                      ? t('booking_has_dangerous_cargo') + '. '
                      : ''}
                    {t('booking_not_updatable')}
                  </Alert>
                )}
                <Flex>
                  <Center mr="3">
                    <Icon
                      as={LocalShippingIcon}
                      fill="baseBlue"
                      width="30px"
                      height="30px"
                    />
                  </Center>
                  <Box>
                    <Text fontSize="2xl" color="baseBlue" fontWeight="400">
                      {t('vehicle_information')}
                    </Text>
                  </Box>
                </Flex>
                <FormInput
                  variant="custom"
                  disabled={areFieldsDisabled}
                  setValueAs={setRegNumberAs}
                  placeholder="ABC123ABC"
                  name="vehicleRN"
                  label={t('vehicleRN')}
                  control={control}
                  isReadOnly
                  isDisabled
                  rules={vehicleRegNumberRule({
                    required: true,
                    shouldFilterNotAllowedRegNumbers: true
                  })}
                />
                <FormInput
                  disabled={areFieldsDisabled}
                  variant="custom"
                  setValueAs={setRegNumberAs}
                  placeholder="ABC123ABC"
                  name="trailerRN"
                  rules={vehicleRegNumberRule()}
                  label={
                    <BaseFormLabel
                      tooltipText={t('only_latin_letters_and_numbers')}
                      icon={ErrorIcon}
                      label={t('trailerRN')}
                      iconProps={{ fill: 'yellow.400' }}
                    />
                  }
                  control={control}
                />
                <FormInput
                  variant="custom"
                  disabled={areFieldsDisabled}
                  placeholder="1000"
                  name="loadWeight"
                  label={t('loadWeight')}
                  control={control}
                  setValueAs={(value) => toNumber(value, 0, 35000)}
                  rules={{
                    required: true
                  }}
                />
                <FormSelect
                  isDisabled={areFieldsDisabled}
                  variant="custom"
                  control={control}
                  name="cargoType"
                  label={t('cargoType')}
                >
                  <option value="">{t('select_from_dropdown')}</option>
                  {cargoTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </FormSelect>
              </Stack>
              <Stack spacing={5}>
                <Flex>
                  <Center mr="3">
                    <Icon as={FaceIcon} fill="baseBlue" width="30px" height="30px" />
                  </Center>
                  <Box>
                    <Text fontSize="2xl" color="baseBlue" fontWeight="400">
                      {t('driver_information')}
                    </Text>
                  </Box>
                </Flex>
                <DriversListUpdate isFormDisabled={areFieldsDisabled} />
              </Stack>
              <Stack spacing={5}>
                <FormInput
                  variant="custom"
                  disabled={areFieldsDisabled}
                  placeholder=""
                  name="consignee"
                  label={t('consignee')}
                  control={control}
                  rules={{
                    required: true
                  }}
                />
              </Stack>
              <Stack spacing={5}>
                <FormCheckbox
                  isDisabled={areFieldsDisabled}
                  control={control}
                  name="dangerousGoods"
                  description={t('accepting_terms_of_dangerous_goods')}
                  rules={{ required: 'accepting_terms_error' }}
                ></FormCheckbox>
                <FormCheckbox
                  isDisabled={areFieldsDisabled}
                  control={control}
                  name="allDataIsCorrect"
                  description={t('accepting_terms_of_data_correctness')}
                  rules={{ required: 'accepting_terms_error' }}
                />
              </Stack>
              <Stack spacing={5} alignItems="center">
                <BaseSubmitButton
                  isDisabled={!bookingFound || !isUpdatable}
                  isLoading={pending || fetchingBooking}
                  variant="second"
                  onClick={onSubmit}
                >
                  {t('save_changes')}
                </BaseSubmitButton>
              </Stack>
            </Stack>
          </Box>
        </Container>
      </FormProvider>
    </View>
  )
})

export default DriverUpdateContainer
