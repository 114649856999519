import React, { useState } from 'react'
import BaseInput from '../../../../components/Form/BaseInput'
import Suggest, { SuggestProps } from './Suggest'
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  UseFormStateReturn
} from 'react-hook-form'
import { CargoNameListRow } from '../../../../api/cargoSelfServiceAPI'
import { Box, InputProps } from '@chakra-ui/react'
import { ErrorIcon } from '../../../../icons'
import BaseFormLabel from '../../../../components/Form/BaseFormLabel'
import { useTranslation } from 'react-i18next'

type Props<T extends FieldValues> = {
  field: ControllerRenderProps<T, Path<T>>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<T>
  errorMessage: string
  drivers: CargoNameListRow[]
  handleSelect: SuggestProps['handleSelect']
  handleDelete: SuggestProps['handleDelete']
  driver: CargoNameListRow
  variant: InputProps['variant']
  disabled?: boolean
}

const Autofill = <T extends FieldValues>(props: Props<T>) => {
  const {
    field,
    fieldState,
    errorMessage,
    drivers,
    handleDelete,
    handleSelect,
    driver,
    variant,
    disabled
  } = props

  const [focus, setFocus] = useState(false)
  const { t } = useTranslation()

  return (
    <Box position="relative">
      <BaseInput
        variant={variant}
        label={
          <BaseFormLabel
            label={t('firstname')}
            tooltipText={t('only_latin_letters')}
            icon={ErrorIcon}
            iconProps={{ fill: 'yellow.400' }}
          />
        }
        size="md"
        autoComplete="new-password"
        isInvalid={fieldState.invalid}
        helperText={errorMessage}
        {...field}
        disabled={disabled}
        onFocus={() => setFocus(true)}
        value={field.value}
      />
      <Suggest
        close={() => setFocus(false)}
        drivers={drivers}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        driver={driver}
        open={focus}
      />
    </Box>
  )
}

export default Autofill
